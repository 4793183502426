import { DateService } from '../../../utils/dateService';

const formatDate = (date) => `${DateService.getDDMMFormat(date)} ${DateService.getHHmmFormat(date)}`;

const currentMove = (move, filteredTrips, tripNo) => {
  const trip = filteredTrips.find((t) => t.data.trip_no === tripNo);
  return trip.data.moves.find((m) => m.entity_id === move.entity_id);
};

export const formatTCMoves = (moves, filteredTrips, tripNo, addresses) => [...moves].map((move) => {
  const current = currentMove(move, filteredTrips, tripNo);
  return {
    id: move.entity_id,
    position: move?.data?.position,
    move: (move?.data?.position || 0) + 1,
    time: formatDate(current.data.date),
    from: move?.data?.trailer_id,
    to: current?.data?.trailer_id,
    action: current?.data?.action,
    shipments: current?.data?.shipment_ids,
    routes: addresses[current?.data?.destination_id]?.data?.samsara_name || '',
  };
});
