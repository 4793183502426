import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Dropdown, Spinner, Stack,
} from 'react-bootstrap';
import { Plus } from 'react-feather';
import { toast } from 'react-toastify';

import useAppDispatch from '../../../hooks/useAppDispatch';

import ShipmentCard from './ShipmentCard';
import TripSummaryContainer from '../../shipments/ShipmentDetails/TripSummary/TripSummaryContainer';
import ShipmentsModal from '../../../components/modals/ShipmentsModal/ShipmentsModal';

import { createTripShipment, setTripDetails, updateTripShipments } from '../../../redux/slices/trips/trips';
import { selectContactsObject, selectShipmentBoardSettings } from '../../../redux/slices/settings';
import { DateService } from '../../../utils/dateService';
import { getShipments } from '../../../redux/slices/shipments';
import Analytics from '../../../utils/analytics';
import { TripDetails } from '../../../redux/models/trip.models';
import { Shipment, defaultShipmentListFilters } from '../../../redux/models/shipment.models';
import Permission from '../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import { resetShipments } from '../../../redux/slices/shipment-list/shipment-list';

const SECTION_TITLE = 'Shipments';
// const ADD_SHIPMENT_TEXT = 'Add Shipment';
const NO_SHIPMENTS = 'No shipments were added.';

const NEW_SHIPMENT_OPTION = 'New';
const EXISTING_SHIPMENT_OPTION = 'Existing';

const WriteShipmentPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Write,
};

interface Props {
  tripDetails: TripDetails;
  shipments: Shipment[];
  page: string;
  isEditable: boolean;
}

export default function TripShipments({
  tripDetails,
  shipments = [],
  page,
  isEditable,
}: Props) {
  const dispatch = useAppDispatch();
  const contactsObject = useSelector(selectContactsObject);
  const shipmentSettings = useSelector(selectShipmentBoardSettings);
  const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(null);
  const [shouldShowShipmentsModal, setShouldShowShipmentsModal] = useState(false);
  const [isAddingShipments, setIsAddingShipments] = useState(false);
  const [orderedShipments, setOrderedShipments] = useState(shipments);

  const handleShowShipmentModal = () => setShouldShowShipmentsModal(!shouldShowShipmentsModal);

  const handleSelectShipment = (shipment: Shipment) => {
    if (selectedShipment?.entity_id === shipment?.entity_id) {
      return setSelectedShipment(null);
    }
    return setSelectedShipment(shipment);
  };

  const onRemoveShipment = async (shipmentId: string) => {
    const updatedShipmentsData = shipments
      .filter((shipment) => shipment.entity_id !== shipmentId);
    try {
      dispatch(resetShipments());
      dispatch(updateTripShipments(tripDetails, updatedShipmentsData));
    } catch (error) {
      Analytics.capture(error);
    }
  };

  const handleAddShipment = async () => {
    try {
      setIsAddingShipments(true);
      const trip = await createTripShipment(tripDetails);
      dispatch(resetShipments());
      dispatch(setTripDetails(trip));
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage =
          `Couldn't create shipment. ${error.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
        Analytics.capture(error);
      }
    } finally {
      setIsAddingShipments(false);
    }
  };
  
  useEffect(() => {
    const ordered = [...shipments].sort((a, b) => {
      const aCustomerId = a.data?.customer_id || '';
      const bCustomerId = b.data?.customer_id || '';
      const aName = contactsObject[aCustomerId]?.data?.name || '';
      const bName = contactsObject[bCustomerId]?.data?.name || '';
      return aName.localeCompare(bName);
    });
    setOrderedShipments(ordered);
  }, [shipments, contactsObject]);

  const handleShipmentModal = () => {
    const filters = {
      ...defaultShipmentListFilters,
      dateFrom: DateService.subtractDaysFromDateISO(new Date(), 3),
      dateTo: DateService.addDaysToDateISO(new Date(), 1),
    };
    dispatch(getShipments(filters, shipmentSettings));
  };

  return (
    <>
      <div className="mt-2 pb-2">
        <Row className="pb-2">
          <Col>
            <h4>
              {SECTION_TITLE}
            </h4>
          </Col>
          <Col>
            <Permission resources={WriteShipmentPermissions}>
              <Stack direction="horizontal" className="float-end" gap={3}>
                {isAddingShipments && (
                  <div className="float-end">
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      style={{ marginRight: '4px' }}
                    />
                  </div>
                )}
                <Dropdown
                  id={`${page}-addShipment__btn`}
                  // disabled={shouldDisableSave}
                  className="float-end"
                  // variant="primary"
                  // title={ADD_SHIPMENT_TEXT}
                  onClick={handleShipmentModal}
                >
                  <Dropdown.Toggle variant="light" className="bg-white shadow-sm" data-cy="addShipment_btn">
                    <Plus className="feather align-middle mt-n1" /> Shipment
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      id={`${page}-newShipment__btn`}
                      data-cy="newShipment_btn"
                      onClick={handleAddShipment}>
                      {NEW_SHIPMENT_OPTION}
                    </Dropdown.Item>
                    <Dropdown.Item
                      id={`${page}-existingShipment__btn`}
                      data-cy="existingShipment_btn"
                      onClick={handleShowShipmentModal}
                    >
                      {EXISTING_SHIPMENT_OPTION}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            </Permission>
          </Col>
        </Row>
        <Row className="shipment-card-container" data-cy="shipment-card-container">
          {orderedShipments.length ? orderedShipments.map((shipment, index) => (
            <Col md={4} key={shipment.entity_id}>
              <ShipmentCard
                page={page}
                shipment={shipment}
                tripDetails={tripDetails}
                selectShipment={handleSelectShipment}
                onRemoveShipment={onRemoveShipment}
                dataCy={`shipmentCard_${index}`}
                isEditable={isEditable}
              />
            </Col>
          )) : <p>{NO_SHIPMENTS}</p>}
        </Row>
      </div>
      {selectedShipment && <TripSummaryContainer shipmentId={selectedShipment.entity_id} isEditable={isEditable} />}
      {shouldShowShipmentsModal && (
        <ShipmentsModal
          shouldShowShipmentsModal={shouldShowShipmentsModal}
          handleShowShipmentModal={handleShowShipmentModal}
          tripDetails={tripDetails}
        />
      )}
    </>
  );
}
