/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getOrgCode } from '../../config';
import ProjectContainer from './ProjectContainer';

function OrganisationList() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [shouldLoad, setShouldLoad] = useState(false);
  useEffect(() => {
    const stageOrg = (params: URLSearchParams) => {
      const savedOrg = getOrgCode();
      const reload = params.get('load') || false;
      if (savedOrg) {
        // redirect
        if (reload) {
          setShouldLoad(true);
        } else {
          navigate(`/${savedOrg}/boards`);
        }
      } else {
        setShouldLoad(true);
      }
    }
    stageOrg(searchParams);
  }, [searchParams]);

  return <ProjectContainer fetch={shouldLoad} />;
}

export default OrganisationList;
