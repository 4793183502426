/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button, Stack, Dropdown, ButtonGroup, DropdownButton, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { getTrips, selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import TripPlannerTripsCard from './TripPlannerTripsCard';
import { Driver, Group, Tag } from '../../../redux/models/settings.models';
import { createDropdownOptions, formatOrgName } from '../../../utils/core.utils';
import { BoardSettings } from '../../../redux/models/board.models';
import TripPlannerDriverCard from './TripPlannerDriverCard';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { TripPlan } from '../../../redux/slices/planner/planner.models';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import {
 resetFilters,
 applyDraft,
 removeCurrentPlan,
 saveDraft,
 dispatchDraft,
 optimisePlan,
 setCurrentPlan,
 selectDriverFilters,
 updateDraftDriver,
} from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import DraftNotesModal from './DraftNotesModal';
import SavePlanButton from './components/SavePlanButton';
import ResetPlanButton from './components/ResetPlanButton';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import ApiResponse from '../../../services/base/ApiResponse';
import ButtonSpinner from '../../../components/shared/ButtonSpinner';
import { findDriverAvailability, sortPlannedDrivers } from '../../../redux/slices/planner/planner.utils';
import DropWrapper from './components/DropWrapper';
import { ItemModel } from './components/ItemModel';
import Analytics from '../../../utils/analytics';
import { DateService } from '../../../utils/dateService';
import useAppSettings from '../../../hooks/useAppSettings';
import { setMultiTripDispatch } from '../../../redux/slices/notifications/notifications';
import { AsyncDispatchNotificationResponse } from '../../../redux/slices/dispatch/dispatch.models';

const tripsFilter = (driverId: string, plan: TripPlan) => {
  const plans = plan.data.plans || {};
  const assigned = plans[driverId] && plans[driverId].length > 0;
  return assigned;
};

const avFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

const tagFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

interface Props {
  availability: DriverAvailability[];
  boardSettings: BoardSettings;
  drivers: Driver[];
  groups: Group[];
  plan: TripPlan;
  tags: Tag[];
  tripContainer: EntityContainer<TripBoardModel>;
}

export default function TripPlannerContainer({
  availability, groups, drivers, boardSettings, plan, tripContainer, tags
}: Props) {
  const dispatch = useAppDispatch();
  const { id, orgCode } = useParams();
  const { optimise_planner: optimisePlanner, planner_dispatch: dispatchPlanner } = useFeatureFlags();
  const { timezone } = useAppSettings();
  const filters = useSelector(selectTripsFilterParams);
  const selectedDriverFilter = useSelector(selectDriverFilters);
  const {
    available,
    numTrips,
    tagFilters,
    outsideCartage
  } = selectedDriverFilter;
  const isTagFilters = Object.values(tagFilters).find((filter) => filter);
  const isFilter = numTrips || available || isTagFilters || outsideCartage;
  const [isOptimising, setIsOptimising] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isConfirmAssignOpen, setIsConfirmAssignOpen] = useState(false);
  const [isConfirmAssignDispatchOpen, setIsConfirmAssignDispatchOpen] = useState(false);
  const [isAssigningDrivers, setIsAssigningDrivers] = useState(false);
  const isNotes = plan.data.notes !== '' && plan.data.notes !== undefined && plan.data.notes !== null;
  const offset = DateService.getTimezoneOffset(timezone);
  const driverOptions = createDropdownOptions(drivers, 'samsara_name');
  const isOptmiseEnabled = optimisePlanner?.is_enabled || false;
  const isDispatchEnabled = dispatchPlanner?.is_enabled || false;
  const unSortedData = isFilter ? drivers.filter((option) => {
    const driverId = option.entity_id;
    const driverFilters: boolean[] = [];
    const isSelected = selectedDriverFilter.selected[driverId] || false;
    if (numTrips) driverFilters.push(tripsFilter(driverId, plan));
    if (available) driverFilters.push(avFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (outsideCartage) driverFilters.push(option.data.outside_cartage);
    if (isSelected) driverFilters.push(isSelected);
    return driverFilters.find((flt) => flt);
  }) : drivers;
  const driverData = sortPlannedDrivers(filters.dateFrom, unSortedData, availability);

  const handleOpenNotes = () => {
    setIsNotesOpen(true);
  };
  const handleConfirmApply = () => {
    setIsConfirmAssignOpen(true);
  }
  const handleConfirmApplyDispatch = () => {
    setIsConfirmAssignDispatchOpen(true);
  }
  const handleApply = async () => {
    try {
      setIsAssigningDrivers(true);
      const saveResponse = await saveDraft(plan);
      const saveEntity: any = ApiResponse.parseItem(saveResponse);
      await applyDraft(saveEntity);
      await dispatch(removeCurrentPlan(plan.entity_id));
      await dispatch(getTrips(id || '', filters, boardSettings));
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error assigning drivers. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsAssigningDrivers(false);
      Analytics.applyDraft(orgCode);
    }
  };
  const handleDispatch = async () => {
    try {
      setIsAssigningDrivers(true);
      const saveResponse = await saveDraft(plan);
      const saveEntity: any = ApiResponse.parseItem(saveResponse);
      const planDispatch = {
        user_timezone: offset,
        company_name: formatOrgName(orgCode),
      }
      const response = await dispatchDraft(saveEntity, planDispatch);
      if (response.status === 200) {
        const queuedDispatches: AsyncDispatchNotificationResponse = {
          ...response.data.data?.dispatches,
          message: 'Finished processing dispatches for trip plan'
        };
        console.log('handleDispatch: ');
        console.log('dispatchDraft: ');
        console.log(queuedDispatches);
        await dispatch(setMultiTripDispatch(queuedDispatches));
        await dispatch(removeCurrentPlan(plan.entity_id));
        await dispatch(getTrips(id || '', filters, boardSettings));
      }
    } catch (error) {
      if (error instanceof Error) {
        toast(`Error assigning drivers. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsAssigningDrivers(false);
      Analytics.dispatchDraft(orgCode);
    }
  };

  // const handleLocalOptimise = async (greedy: boolean) => {
  //   const data = optimiseTrips(
  //     filters.dateFrom,
  //     availability,
  //     drivers,
  //     tripContainer,
  //     boardSettings.addresses,
  //     greedy
  //   );
  //   const optmisedPlans: EntityContainer<string[]> = data.reduce((store, driver) => {
  //     return {
  //       ...store,
  //       [driver.id]: driver.assignedTrips.map((tr) => tr.id),
  //     }
  //   }, {});
  //   const optimisedContainer: EntityContainer<string> = Object.values(optmisedPlans)
  //     .flatMap((draft) => draft)
  //     .reduce((container, draftTripId) => {
  //       return {
  //         ...container,
  //         [draftTripId]: draftTripId,
  //       };
  //     }, {});
  //   const unassigned: string[] = Object.values(tripContainer)
  //     .filter((trip) => !optimisedContainer[trip.id])
  //     .map((trip) => trip.id);
    
  //   const optimised: TripPlan = {
  //     ...plan,
  //     data: {
  //       ...plan.data,
  //       unassigned_trip_ids: unassigned,
  //       plans: {
  //         ...plan.data.plans,
  //         ...optmisedPlans,
  //       },
  //     },
  //   };
  //   dispatch(setCurrentPlan(optimised));
  //   Analytics.optimiseDispatch(orgCode, greedy);
  // };

  const handleNetworkOptimise = async (greedy: boolean) => {
    try {
      setIsOptimising(true);
      const driverIds = drivers.map((dr) => dr.entity_id);
      const response = await optimisePlan(plan, driverIds, greedy);
      if (response.status === 200) {
        const optimised: TripPlan = response.data.data;
        dispatch(setCurrentPlan(optimised));
      }
    } catch (error) {
      Analytics.capture(error);
      toast("Couldn't optimise trips", { type: 'error' });
    } finally {
      setIsOptimising(false);
      Analytics.optimiseDispatch(orgCode, greedy);
    }
  };

  const handleResetFilters = () => {
    dispatch(resetFilters());
  };
  const onDrop = (item: ItemModel, _: any, driverId: string) => {
    dispatch(updateDraftDriver(driverId, item.trip));
  };
  return (
    <>
      <Stack direction="horizontal" className="mb-3 justify-content-between" gap={3}>
        <Stack direction="horizontal" gap={3}>
          <h4>Draft Plan</h4>
          {isOptmiseEnabled && !isOptimising && (
            <DropdownButton
              as={ButtonGroup}
              title="Assign"
              id="bg-vertical-dropdown-1"
              variant="outline-primary"
            >
              <Dropdown.Item onClick={() => handleNetworkOptimise(false)}>Evenly</Dropdown.Item>
              <Dropdown.Item onClick={() => handleNetworkOptimise(true)}>Greedy</Dropdown.Item>
            </DropdownButton>
          )}
          {isOptmiseEnabled && isOptimising && (
            <ButtonSpinner />
          )}
          <Button type="button" variant={isNotes ? 'primary' : 'light'} onClick={handleOpenNotes}>
            Notes {isNotes && <Badge bg="secondary">1</Badge>}
          </Button>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <Button type="button" variant="outline-secondary" onClick={handleResetFilters}>Reset Filters</Button>
          <ResetPlanButton plan={plan} trips={tripContainer} />
          <Dropdown as={ButtonGroup}>
            <SavePlanButton plan={plan} />
            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleConfirmApply}>Apply</Dropdown.Item>
              {isDispatchEnabled && (
                <Dropdown.Item onClick={handleConfirmApplyDispatch}>Dispatch</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </Stack>
      <Row>
        <DndProvider backend={HTML5Backend}>
          <Col md={6}>
            <TripPlannerDriverCard
              data={driverData}
              dateFilters={filters}
              availability={availability}
              drivers={drivers}
              driverOptions={driverOptions}
              boardSettings={boardSettings}
              plan={plan}
              tags={tags}
              handleDrop={onDrop}
            />
          </Col>
          <Col md={6}>
            <DropWrapper
              driverId=""
              onDrop={onDrop}
            >
              <TripPlannerTripsCard
                groups={groups}
                drivers={driverOptions}
                plan={plan}
              />
            </DropWrapper>
        </Col>
        </DndProvider>
      </Row>
      {isNotesOpen && plan && (
        <DraftNotesModal plan={plan} shouldShow={isNotesOpen} onClose={() => setIsNotesOpen(false)} />
      )}
      {isConfirmAssignOpen && (
        <ConfirmModal
          title="Confirm Assign Draft"
          details="Would you like to assign the planned trips to these drivers?"
          btnTitle="Assign"
          saveDisabled={isAssigningDrivers}
          shouldShowModal={isConfirmAssignOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleApply}
          cancelSave={() => setIsConfirmAssignOpen(false)}
        />
      )}
      {isConfirmAssignDispatchOpen && (
        <ConfirmModal
          title="Confirm Dispatch Draft"
          details="Would you like to dispatch the planned trips to these drivers?"
          btnTitle="Dispatch"
          saveDisabled={isAssigningDrivers}
          shouldShowModal={isConfirmAssignDispatchOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleDispatch}
          cancelSave={() => setIsConfirmAssignOpen(false)}
        />
      )}
    </>
  );
}