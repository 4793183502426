/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Table } from 'react-bootstrap';
import { EntityContainer } from '../../../../redux/models/core.models';
import { DriverAvailability } from '../../../../redux/models/driver.availability.models';
import DriverDetailsAvailabilityItem from './DriverDetailsAvailabilityItem';
import { Driver } from '../../../../redux/models/settings.models';

const days = [1,2,3,4,5,6,7];

interface Props {
  availability: EntityContainer<DriverAvailability>;
  driver: Driver;
  setAvailability: React.Dispatch<React.SetStateAction<EntityContainer<DriverAvailability>>>;
}

export default function DriverDetailsAvailability({ availability, driver, setAvailability }: Props) {
  const handleOnUpdate = (av: DriverAvailability | null, day: number) => {
    if (av) {
      const updates = {
        ...availability,
        [day]: av,
      }
      setAvailability(updates);
    } else {
      // delete
      const updates = {
        ...availability,
      }
      delete updates[day];
      setAvailability(updates);
    }
  };
  return (
    <div>
      <h4>Availability</h4>
      <Table bordered hover className="settings-table" data-cy="driver_details_editor_availability_table">
        <thead>
          <th>Day</th>
          <th>Start</th>
          <th>Finish</th>
          <th />
        </thead>
        <tbody>
          {days.map((day) => (
            <DriverDetailsAvailabilityItem
              key={day}
              availability={availability[`${day}`]}
              day={day}
              driver={driver}
              onUpdate={handleOnUpdate}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
