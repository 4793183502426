import {
  Card, Row, Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useAppDispatch from '../../../../hooks/useAppDispatch';
import { addShipmentTrip } from '../../../../redux/slices/shipments';
import { addShipmentTrip as addShipmentTripPaginated } from '../../../../redux/slices/shipment-list/shipment-list';

import TripSummaryTable from './TripSummaryTable/TripSummaryTable';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import { Shipment } from '../../../../redux/models/shipment.models';
import { TripListViewData } from '../../../../redux/models/trip.models';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

const ADD_TRIP_TEXT = 'Add Trip';
const WriteShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

interface Props {
  shipmentDetails: Shipment;
  isEditable: boolean;
  showDocuments?: boolean;
  openDocuments?: (trip: TripListViewData) => void;
}

function TripSummary({ isEditable, shipmentDetails, showDocuments = false, openDocuments = () => {} }: Props) {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const { orgCode } = useParams();
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const title = `${shipmentDetails?.data?.shipment_no} Trip Summary`;
  const trips = shipmentDetails?.data?.trips || [];
  const handleAdd = () => {
    if (isPaginationEnabled) {
      dispatch(addShipmentTripPaginated(shipmentDetails, orgCode));
    } else {
      dispatch(addShipmentTrip(shipmentDetails, orgCode));
    }
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={8}>
            <h4>{title}</h4>
          </Col>
          <Col md={4}>
            <Permission resources={WriteShipmentPermissions}>
              <AsyncButton
                id="shipmentDetails-addTrip__btn"
                title={ADD_TRIP_TEXT}
                dataCy="shipmentDetails-addTrip__btn"
                variant="outline-primary"
                className="float-end"
                handleClick={handleAdd}
              />
            </Permission>
          </Col>
        </Row>
        <Row className="mt-2">
          <TripSummaryTable
            isEditable={isEditable}
            trips={trips}
            shipmentDetails={shipmentDetails}
            showDocuments={showDocuments}
            openDocuments={openDocuments}
          />
        </Row>
      </Card.Body>
    </Card>
  );
}

TripSummary.defaultProps = {
  showDocuments: false,
  openDocuments: () => {},
};

export default TripSummary;