import React from 'react';

import { TripPlan } from '../../../../redux/slices/planner/planner.models';
import { setCurrentPlan } from '../../../../redux/slices/planner/planner';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import AsyncButton from '../../../../components/shared/buttons/AsyncButton';
import { EntityContainer } from '../../../../redux/models/core.models';
import { TripBoardModel } from '../../../../redux/models/trip.models';

interface Props {
  plan: TripPlan;
  trips: EntityContainer<TripBoardModel>;
}

export default function ResetPlanButton({ plan, trips }: Props) {
  const dispatch = useAppDispatch();
  const handleSavePlan = async () => {
    const plans = plan.data.plans || {};
    const resetDrivers: EntityContainer<string[]> = Object.keys(plans).reduce((store, key) => {
      return {
        ...store,
        [key]: [],
      };
    }, {});
    const unassigned = Object.values(trips).map((trip) => trip.id);
    const updates: TripPlan = {
      ...plan,
      data: {
        ...plan.data,
        unassigned_trip_ids: unassigned,
        plans: {
          ...resetDrivers,
        },
      },
    };
    dispatch(setCurrentPlan(updates));
  };
  return <AsyncButton title="Reset" variant="outline-secondary" spinner="secondary" handleClick={handleSavePlan} />
}