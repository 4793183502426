/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Stack } from 'react-bootstrap';
import { Group } from '../../../redux/models/settings.models';
import DropdownButton from '../../../components/shared/DropdownButton';
import { EntityContainer } from '../../../redux/models/core.models';
import { DropdownOption } from '../../../components/shared/dropdown.models';
import { selectTrips } from '../../../redux/slices/trips/trips';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { DropdownOptionsModel } from '../../../components/shared/SelectInput.models';
import { TripFilters, TripPlan } from '../../../redux/slices/planner/planner.models';
import { selectTripFilters, setTripFilters } from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import CrapTable from './components/table/CrapTable';

export const DropdownOptions: EntityContainer<DropdownOption> = {
  group: {
    id: 'group',
    value: 'Group'
  },
}

const groupByOptions = Object.values(DropdownOptions);

const getGroupedTrips = (trips: TripBoardModel[]) => {
  const store: EntityContainer<TripBoardModel[]> = {}
  return trips.reduce((container, trip) => {
    if (!trip) {
      return {
        ...container,
      }
    }
    const groupId = trip?.groupId || '';
    const gr = container[groupId] || [];
    const isEmpty = gr.length === 0;
    return {
      ...container,
      [groupId]: isEmpty ? [trip] : [...gr, trip],
    }
  }, store)
};

interface Props {
  groups: Group[];
  drivers: DropdownOptionsModel[];
  plan: TripPlan;
  isOver?: boolean;
}

function TripPlannerTripsCard({ groups, drivers, plan, isOver }: Props) {
  const dispatch = useAppDispatch();
  const tripStore: EntityContainer<TripBoardModel> = useSelector(selectTrips);
  const unassignedDrafts = plan.data.unassigned_trip_ids || [];
  const filters = useSelector(selectTripFilters);
  const {
    groupBy,
  } = filters;
  const isGrouped = groupBy !== '';
  const trips = unassignedDrafts.map((draft) => tripStore[draft]);
  const groupedTrips: EntityContainer<TripBoardModel[]> = isGrouped ? getGroupedTrips(trips) : {};
  const unGroupedTrips = groupedTrips[''] || [];
  const handleSelectGroupBy = (optionId: string) => {
    const updates: TripFilters = {
      ...filters,
      groupBy: optionId,
    };
    dispatch(setTripFilters(updates));
  };
  const activeStyles: any = { maxHeight: '800px', overflowY: 'scroll' };
  if (isOver) {
    activeStyles.background = '#F1F5F9';
  }

  return (
    <Card>
      <Card.Body>
        <Stack gap={2} className="pb-4">
          <Stack className="pb-4" direction="horizontal" gap={4}>
            <h4>Trips {`(${trips.length})`}</h4>
          </Stack>
          <DropdownButton
            selectText="None"
            selectedId={groupBy}
            options={groupByOptions}
            handleSelect={handleSelectGroupBy}
          />
        </Stack>
        <div style={activeStyles}>
          {!isGrouped && (
            <CrapTable driverId="" plan={plan} trips={trips} drivers={drivers} />
          )}
          {isGrouped && (
            <Stack gap={3}>
              {unGroupedTrips.length > 0 && (
                <Stack>
                  <h5>No Group</h5>
                  <CrapTable driverId="" plan={plan} trips={unGroupedTrips} drivers={drivers} />
                </Stack>
              )}
              {groups.map((group) => {
                const data = groupedTrips[group.entity_id] || [];
                const groupName = group.data.name || 'No Group';
                const isTrips = data.length > 0;
                if (!isTrips) return null;
                return (
                  <Stack key={group.entity_id}>
                    <h5>{groupName}</h5>
                    <CrapTable driverId="" plan={plan} trips={data} drivers={drivers} />
                  </Stack>
                );
              })}
            </Stack>
          )}
        </div>
        
      </Card.Body>
    </Card>
  );
}

TripPlannerTripsCard.defaultProps = {
  isOver: false,
};

export default TripPlannerTripsCard;
