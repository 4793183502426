/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './TrailerChangeModal.scss';

import { Button, Modal, Spinner } from 'react-bootstrap';
import { CollapseButton } from '../../shared/CollapseButton';

import TrailerChangeCard from './TrailerChangeCard';
import TrailerChangeMsg from './TrailerChangeMsg';

import { MODAL_TITLE } from './trailerChange.constants';

const CANCEL_BUTTON = 'Cancel';
const SAVE_BUTTON = 'Save and Close';

const SAVE_MODAL_MESSAGE = 'Or click SAVE to apply no further changes';

const SAVE_MODAL_APPLY_CHANGES_BUTTON = 'Apply Changes';
const SAVE_MODAL_DISMISS_BUTTON = 'Dismiss';
const SAVE_MODAL_SAVE_BUTTON = 'Save Without Further Changes';

export function TrailerChangeModal({
  name,
  showTrailerChangeModal,
  handleModalClose,
  fleet,
  initialMoves,
  trips,
  handleTrailerChangeSave,
  isSaving,
  fleetLocations,
}: any) {
  const [shouldExpandAllItems, setShouldExpandAllItems] = useState(true);

  return (
    <Modal
      show={showTrailerChangeModal}
      onHide={handleModalClose}
      backdrop="static"
      scrollable
      dialogClassName="large-modal"
      data-cy={`${name}-modal`}
    >
      <Modal.Header>
        <Modal.Title>{MODAL_TITLE}</Modal.Title>
        <div className={`${name}-collapse-btn-wrapper`}>
          <CollapseButton
            shouldExpandAllItems={shouldExpandAllItems}
            setShouldExpandAllItems={setShouldExpandAllItems}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <TrailerChangeMsg
          fleet={fleet}
          initialMoves={initialMoves}
          buttonName="Save and Close"
        />
        {trips && trips.filter(
          (trip: any) => trip.data.moves.length > 0,
        ).map((trip: any) => (
          <TrailerChangeCard
            name={name}
            key={trip?.version}
            shouldExpandAllItems={shouldExpandAllItems}
            trip={trip}
            fleetLocations={fleetLocations}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id={`${name}-cancel__btn`}
          variant="light"
          onClick={handleModalClose}
          data-cy={`${name}-cancel__btn`}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          id={`${name}-saveAll__btn`}
          data-cy={`${name}-saveAll__btn`}
          onClick={handleTrailerChangeSave}
        >
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: '4px' }}
            />
          )}
          {SAVE_BUTTON}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function TrailerChangeSaveModal({
  handleChangeModals,
  handleModalClose,
  showTrailerSaveModal,
  handleSave,
  fleet,
  initialMoves,
  isSaving,
  shouldDisableSave,
}: any) {
  return (
    <Modal
      id="trailerChanges-saveModal"
      data-cy="trailerChanges-saveModal"
      show={showTrailerSaveModal}
      onHide={handleModalClose}
      size="lg"
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>{MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="trailer-change-message">
          <TrailerChangeMsg
            fleet={fleet}
            initialMoves={initialMoves}
            buttonName="Apply Changes"
          />
        </div>
        {SAVE_MODAL_MESSAGE}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="trailerChanges-dismiss__btn"
          data-cy="trailerChanges-dismiss__btn"
          variant="light"
          onClick={handleModalClose}
        >
          {SAVE_MODAL_DISMISS_BUTTON}
        </Button>
        <Button
          id="trailerChanges-apply__btn"
          data-cy="trailerChanges-apply__btn"
          disabled={shouldDisableSave}
          onClick={handleChangeModals}
        >
          {SAVE_MODAL_APPLY_CHANGES_BUTTON}
        </Button>
        <Button
          id="trailerChanges-save__btn"
          data-cy="trailerChanges-save__btn"
          disabled={shouldDisableSave}
          onClick={handleSave}
        >
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: '4px' }}
            />
          )}
          {SAVE_MODAL_SAVE_BUTTON}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
