import React, { useState, useEffect } from 'react';

import './TrailerChangeModal.scss';
import { Accordion, Card } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import TrailerChangeModalTable from './TrailerChangeModalTable';

import {
  selectBoards, selectGroups,
} from '../../../redux/slices/settings';
import { getValue } from '../../../pages/trips/Moves/utils/moves.utils';

const EXPAND_MODAL = 'Expand';
const COLLAPSE_MODAL = 'Collapse';

export default function TrailerChangeCard({
  name,
  shouldExpandAllItems,
  trip,
  fleetLocations,
}) {
  const [isItemExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);

  useEffect(() => setIsItemExpanded(shouldExpandAllItems), [shouldExpandAllItems]);

  const allGroups = useSelector(selectGroups);
  const allBoards = useSelector(selectBoards);

  const getCardTitle = `${getValue(trip?.data?.board_id, allBoards)} • ${getValue(trip?.data?.group_id, allGroups)}`;

  return (
    <Accordion activeKey={isItemExpanded ? '0' : ''}>
      <Card
        className={`${name}-trip__card`}
        data-cy={`${name}-trip__card`}
      >
        <Accordion.Item eventKey="0">
          <Card.Header className={`${name}-card-header`}>
            <Card.Title tag="h5">{getCardTitle}</Card.Title>
            <Card.Subtitle className={`${name}-message`}>
              {trip?.data?.trip_no || ''}
              <div className={`${name}-btn`} onClick={() => setIsItemExpanded(!isItemExpanded)}>
                {isItemExpanded ? COLLAPSE_MODAL : EXPAND_MODAL}
              </div>
            </Card.Subtitle>
          </Card.Header>
          <Accordion.Body style={{ padding: 0 }}>
            <Card.Body className={`${name}-card-body`}>
              <TrailerChangeModalTable
                name={name}
                moves={trip?.data?.moves}
                shipments={trip?.data?.shipments}
                fleetLocations={fleetLocations}
                tripNo={trip.data.trip_no}
              />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  );
}
