/* eslint-disable */
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';

import { TripBoardModel } from '../../../../../redux/models/trip.models';
import { saveDraft } from '../../../../../redux/slices/planner/planner';
import { TripPlan } from '../../../../../redux/slices/planner/planner.models';
import { ROW_TYPE } from '../ItemModel';
import { DateService } from '../../../../../utils/dateService';
import { DragHandle } from './DragHandle';
import { EntityContainer } from '../../../../../redux/models/core.models';
import { TRIP_STATUS_TYPES } from '../../../../trips/constants/trips.constants';

const getApt = (trip: TripBoardModel) => {
  const date = trip?.apt || '';
  if (date === '') return '';
  const moves = trip?.moves || [];
  const tbd = moves.some((move) => move.schedule_tbd);
  if (tbd) return 'TBD';
  return DateService.getHHmmFormat(date);
};

const STATUS: EntityContainer<string> = TRIP_STATUS_TYPES.reduce((store, status) => {
  return {
    ...store,
    [status.value]: status.label === 'Select...' ? '' : status.label,
  };
}, {});
const getStatus = (trip: TripBoardModel) => {
  const val = trip?.status || '';
  if (!val) return '';
  return STATUS[val];
}

interface Props {
  trip: TripBoardModel;
  plan: TripPlan;
  index: number;
  driverId: string;
}

function CrapTableRow({ trip, plan, index, driverId }: Props) {
  const ref = useRef<any | null>(null);
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const handleOpen = async () => {
    try {
      await saveDraft(plan);
    } catch (error) {
      console.log(error);
    } finally {
      navigate(`/${orgCode}/trips/${trip.id}`);
    }
  };
  const [, drop] = useDrop(() => ({
    accept: ROW_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
  }));
  const [, drag] = useDrag(() => {
    return ({
      type: ROW_TYPE,
      item: { type: ROW_TYPE, trip, driverId, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  });
  drag(drop(ref));
  // onClick={() => handleOpen(cell.column.id, row.original)}
  return (
    <tr className="flex-fill" ref={ref}>
      <td><DragHandle /></td>
      <td onClick={() => handleOpen()}>{trip.trailer || ''}</td>
      <td onClick={() => handleOpen()}>{getApt(trip) || ''}</td>
      <td onClick={() => handleOpen()}>{trip.billingType || ''}</td>
      <td onClick={() => handleOpen()}>{trip.route || ''}</td>
      {/* <td onClick={() => handleOpen()}>{trip.driver || ''}</td> */}
      <td onClick={() => handleOpen()}>{getStatus(trip) || ''}</td>
    </tr>
  )
}

export default CrapTableRow;
