/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormFieldConfig } from '../../../../redux/models/shipment.models';
import { getShipmentFieldType } from '../../../../redux/slices/shipments.utils';

const getField = (field: FormFieldConfig) => {
  return {
    ...field,
    type: getShipmentFieldType(field.key),
  };
};

interface Props {
  field: FormFieldConfig;
  onFieldChange: (field: FormFieldConfig) => void;
}

export default function ShipmentTypeEditorTableRow({ field, onFieldChange }: Props) {
  const key = field?.key || '';
  const visible = field?.visible || false;
  const required = field?.required || false;
  const handleUpdateVisible = (checked: boolean) => {
    if (checked) {
      onFieldChange({
        ...getField(field),
        visible: checked,
      });
    } else {
      onFieldChange({
        ...getField(field),
        visible: checked,
        required: false
      });
    }
  };
  const handleUpdateRequired = (checked: boolean) => {
    onFieldChange({
      ...getField(field),
      required: checked,
    });
  };
  return (
    <tr>
      <td>{field?.name || ''}</td>
      <td>
        <Form.Check
          type="switch"
          id="Visible"
          label="Visible"
          className="ml-2"
          data-cy={`shipment_type_editor_table_row_${key}_visible`}
          checked={visible}
          onChange={(e) => handleUpdateVisible(e.target.checked)}
        />
      </td>
      <td>
        {field.visible && (
          <Form.Check
            type="switch"
            id="required"
            label="Required"
            className="ml-2"
            data-cy={`shipment_type_editor_table_row_${key}_required`}
            checked={required}
            onChange={(e) => handleUpdateRequired(e.target.checked)}
          />
        )}
      </td>
    </tr>
  );
}
