import React from 'react';
import { Link } from 'react-router-dom';
import { AppNotification } from '../../redux/slices/notifications/notifications.models';
import { getOrgCode } from '../../config';

const getRoute = (notification: AppNotification) => {
  const entityId = notification.data.entity_id || '';
  const type = notification.data.entity_type || '';
  if (type === 'trip') return `trips/${entityId}`;
  if (type === 'board') return `boards/${entityId}`;
  if (type === 'shipment') return `shipments/${entityId}`;
  return `${type}/${entityId}`;
};

interface Props {
  notification: AppNotification;
}

export default function NotificationBody({ notification }: Props) {
  const orgCode = getOrgCode();
  const title = notification.data.title || '';
  const entity = notification.data.entity_type || '';
  const route = getRoute(notification);
  return (
    <div>
      {`${title}. `}
      <Link to={`/${orgCode}/${route}`}>
        <span>{`Open ${entity}`}</span>
      </Link>
    </div>
  );
}
