import {
  Badge, Container, OverlayTrigger, Tooltip,
} from 'react-bootstrap';

export function DangerousGoodsIcon() {
  const renderHoverTooltip = (props) => (
    <Tooltip {...props} bsPrefix="dangerous-goods-tooltip">
      <h6 className="header">This shipment contains dangerous goods.</h6>
      <h6 className="details-text">To remove this, go to the shipment details page.</h6>
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderHoverTooltip}>
      <Container data-cy="dangerous_goods_icon" className="dangerous-goods-icon">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="8.48523" width="12" height="12" transform="rotate(-45 0 8.48523)" fill="#E5A54B" />
        </svg>
      </Container>
    </OverlayTrigger>
  );
}

export function DryIcon() {
  const DRY = 'Dry';
  return (
    <Badge pill bg="primary" className="badge-space" data-cy="dry_icon">{DRY}</Badge>
  );
}
