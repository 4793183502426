/* eslint-disable */
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { Move } from '../models/trip.models';
import TrailerChangeApi from '../../services/TrailerChanges/TrailerChangeApi';

const SLICE_NAME = 'trailerChanges';

const initialState = {
  initialMove: {},
  updatedTrailers: {}, // {initail trailer_id: new trailer_id}
  areTripsWithTrailerLoading: false,
  tripsWithTrailerToChange: null, // obj {initial trailer_id : returned API arr }
  tripsArray: [],
  updatedTripsArray: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setInitialMove(state, action) {
      state.initialMove = action.payload;
    },
    setUpdatedTrailers(state, action) {
      state.updatedTrailers = action.payload;
    },
    setTripsWithTrailersToChange(state, action) {
      state.tripsWithTrailerToChange = action.payload;
    },
    setTripsArray(state, action) {
      state.tripsArray = action.payload;
    },
    setUpdatedTripsArray(state, action) {
      state.updatedTripsArray = action.payload;
    },
    setAreTripsWithTrailerLoading(state, action) {
      state.areTripsWithTrailerLoading = action.payload;
    },
    resetTrailerChanges: () => initialState,
  },
});

export const {
  setAreTripsWithTrailerLoading,
  setTripsWithTrailersToChange,
  setInitialMove,
  setTripsArray,
  setUpdatedTripsArray,
  setUpdatedTrailers,
  resetTrailerChanges,
} = slice.actions;

// removes duplicates between trailers changes API call
export const getTripsArray = (tripsObj: any) => (dispatch: any) => {
  const arr = Object.values(tripsObj).flat().filter(
    (trips: any, index: any, self: any) => index === self.findIndex((t: any) => t.entity_id === trips.entity_id),
  );
  dispatch(setTripsArray(arr));
};

export const getTripsWithTrailerChange = (state: any, move: any) => async (dispatch: any) => {
  // dispatch(setAreTripsWithTrailerLoading(true));
  // try {
  //   const container = { ...state };
  //   const { data: trailerChange } = await TrailerChangeApi.getTrailerChangesTrips(
  //     move.trailer_ids,
  //     move.shipment_ids,
  //   );
  //   const ids: string[] = move.trailer_ids || [];
  //   const sortedIds = ids.sort((a, b) => a.localeCompare(b));
  //   const containerKey = sortedIds.reduce((key, id) => `${key}-${id}`, '');
  //   container[containerKey] = trailerChange.data;
  //   dispatch(setTripsWithTrailersToChange(container));
  //   dispatch(getTripsArray(container));
  // } catch (e) {
  //   const errorMessage = "Couldn't complete trailer search. Please contact support if the problem persists";
  //   toast(errorMessage, { type: 'error' });
  // } finally {
  //   dispatch(setAreTripsWithTrailerLoading(false));
  // }
};

export const updateTrailerChanges = async (data: any) => TrailerChangeApi.updateTrailerChanges(data);

export const getInitialMove = (moves: Move[]) => (dispatch: any) => {
  const container: any = {};
  moves.forEach((move: any) => { container[move.entity_id] = move.data; });
  dispatch(setInitialMove(container));
};

export const getUpdatedTrailers = (state: any, initial: any, changed: any) => (dispatch: any) => {
  const container = { ...state };
  container[initial] = changed;
  dispatch(setUpdatedTrailers(container));
};

export const selectUpdatedTrailers = (state: any) => state[SLICE_NAME].updatedTrailers;
export const selectInitialMove = (state: any) => state[SLICE_NAME].initialMove;

export const selectTrailerChangeObj = (state: any) => state[SLICE_NAME].tripsWithTrailerToChange;

export const selectAllState = (state: any) => state[SLICE_NAME];
export const loadingTrailers = (state: any) => state[SLICE_NAME].areTripsWithTrailerLoading;
export const selectTripsArray = (state: any) => state[SLICE_NAME].tripsArray;
export const selectUpdatedTrips = (state: any) => state[SLICE_NAME].updatedTripsArray;

export const trailerChangesReducer = slice.reducer;
