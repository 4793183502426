/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Table } from "react-bootstrap";

// import { MoreHorizontal } from "react-feather";
import { EntityContainer } from "../../../../redux/models/core.models";
import { Board } from "../../../../redux/models/board.models";
import { Trip } from "../../../../redux/models/trip.models";
import { Link, useParams } from "react-router-dom";
import { renderStatus } from "../../../boards/TripTable/TripsTableRow";
import { DateService } from "../../../../utils/dateService";
import { Api } from "../../../../services/services";
import { selectDriversObject } from "../../../../redux/slices/settings";

const PAGE_SIZE = 5;

interface Props {
  boards: EntityContainer<Board>;
  dateFrom: string;
  dateTo: string;
}

function Projects({ boards, dateFrom, dateTo }: Props) {
  const { orgCode } = useParams();
  const drivers = useSelector(selectDriversObject);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const isNextPage = trips.length < total;
  const loadNextPage = async () => {
    setIsLoading(true);
    const response = await Api.Dashboard.tripsOutsideCartage({
      start_date: dateFrom,
      end_date: dateTo,
      page_size: PAGE_SIZE,
    });
    if (response.status === 200) {
      const tripData = response.data.data.items || [];
      const totalData: number = response.data.data.total_count || 0;
      setTrips([...trips, ...tripData]);
      setTotal(totalData);
      setPage(page + 1);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.tripsOutsideCartage({
        start_date: dateFrom,
        end_date: dateTo,
        page_size: PAGE_SIZE,
      });
      if (response.status === 200) {
        const tripData = response.data.data.items || [];
        const totalData: number = response.data.data.total_count || 0;
        setTrips(tripData);
        setTotal(totalData);
      }
      setIsLoading(false);
      setIsLoaded(true);
    };
  load();
  }, [dateFrom, dateTo]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <Card.Title className="mb-0">{`Outside Cartage Trips (${total})`}</Card.Title>
      </Card.Header>
      {!isLoading && isLoaded && (
        <Table striped className="my-0">
          <thead>
            <tr>
              <th>Trip No.</th>
              <th className="d-none d-xl-table-cell">Board</th>
              <th className="d-none d-xl-table-cell">Date</th>
              <th className="d-none d-xl-table-cell">Status</th>
              <th className="d-none d-xl-table-cell">Driver</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => {
              const boardId = trip.data.board_id || '';
              const board = boards[boardId]?.data.name || '';
              const driverId = trip.data.driver_id || '';
              const driverName = drivers[driverId]?.data.samsara_name || 'No name set';
              const driver = driverId !== '' ? driverName : '';
              const status = trip.data.status || '';
              const date = trip.data.date || '';
              const tripNo = trip.data.trip_no || '';
              return (
                <tr key={trip.entity_id}>
                  <td>
                    <Link to={`/${orgCode}/trips/${trip.entity_id}`}>
                      {tripNo}
                    </Link>
                  </td>
                  <td className="d-none d-xl-table-cell">{board}</td>
                  <td className="d-none d-xl-table-cell">{DateService.getDDMMYYYFormat(date)}</td>
                  <td>
                    {renderStatus(status)}
                  </td>
                  <td className="d-none d-xl-table-cell">{driver}</td>
                </tr>
              )
            })}
            {!isLoading && isLoaded && trips.length === 0 && (
              <p>No outside cartage trips</p>
            )}
          </tbody>
        </Table>
      )}
      {!isLoading && isNextPage && (
        <Card.Footer>
          <Button variant="primary" className="mt-2" onClick={loadNextPage}>Load more</Button>
        </Card.Footer>
      )}
    </Card>
  );
}

export default Projects;
