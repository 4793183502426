/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { Button, Table } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import useAppDispatch from '../../../hooks/useAppDispatch';

import { DatePickerComponent as DatePicker } from '../../../components/shared/DatePicker';
import {
  SelectInput,
} from '../../../components/shared/Input';
import { DraggableTableRow } from './DraggableTableRow';

import { updateTripDetailsMoves } from '../../../redux/slices/trips/trips';

import { ACTION_DROPDOWN_OPTIONS, MOVES_TABLE_HEADERS } from './constants/moves.constants';

import { Move, TripDetails } from '../../../redux/models/trip.models';
import {
  // Address,
  Fleet,
} from '../../../redux/models/settings.models';
import TimelinessBadge from '../../../components/timeliness/TimelinessBadge';
// import AddressInput from '../../../components/entities/address/AddressInput';
import MoveDistanceLabel from './components/MoveDistanceLabel';
import { DropdownOptionsModel } from '../../../components/shared/SelectInput.models';
import { isMoveOrderValid } from '../../../redux/slices/trips/trips.utils';
import MoveOrderAlert from './components/MoveOrderAlert';

const OPEN_TEXT = 'Open';

interface Props {
  isEditable: boolean;
  tripDetails: TripDetails;
  moves: Move[];
  page: string;
  shipmentIdsDropdownOptions: any;
  fleetOptions: Fleet[];
  addressOptions: DropdownOptionsModel[];
  setSelectedMove: React.Dispatch<React.SetStateAction<number | null>>;
  onMoveItemChange: (movePosition: number) => (prop: string, value: string) => void;
  onMoveItemDateChange: (movePosition: number) => (prop: string, value: string) => void;
  // onMoveDestinationChange: (movePosition: number) => (prop: string, value: string) => void;
  // handleNewAddress: (prop: string, addr: Address, position: number) => void;
}

export default function MovesTable({
  isEditable,
  tripDetails,
  moves,
  fleetOptions,
  shipmentIdsDropdownOptions,
  page,
  addressOptions,
  setSelectedMove,
  onMoveItemChange,
  onMoveItemDateChange,
  // onMoveDestinationChange,
  // handleNewAddress,
}: Props) {
  const dispatch = useAppDispatch();
  const isOrderValid = isMoveOrderValid(moves);
  const getMoveDate = (move: Move) => {
    const dateStr = move?.data?.scheduled_arrival_time;
    const date = dateStr ? new Date(dateStr) : new Date();
    return date;
  }
  const handleSelectedMove = async (move: Move) => {
    await setSelectedMove(null);
    setSelectedMove(move.data.position);
  };
  const moveTableRow = useCallback((dragIndex: number, hoverIndex: number) => {
    const newMoves = update(moves, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, moves[dragIndex]],
      ],
    }).map((move, index) => ({
      ...move,
      data: {
        ...move.data,
        position: index,
      },
    }));
    dispatch(updateTripDetailsMoves(newMoves));
  }, [dispatch, moves]);

  return (
    <div>
      {!isOrderValid && <MoveOrderAlert />}
      <MoveDistanceLabel trip={tripDetails} />
      <Table bordered responsive>
        <thead>
          <tr>
            {MOVES_TABLE_HEADERS.map(
              (header) => <th key={header.key} id={`movesTable-header-${header.name}`}>{header.name}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          <DndProvider backend={HTML5Backend}>
            {moves.map((move, index) => (
              <DraggableTableRow key={index} index={index} id={index} moveTableRow={moveTableRow}>
                <td
                  align="center"
                  id={`${page}-movesTable__move-${index}`}
                  data-cy={`${page}_moveTable-${index}`}
                  className="table-number"
                >
                  {(move?.data?.position || 0) + 1}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {/* <AddressInput
                    value={move?.data?.destination_id}
                    page={page}
                    prop="destination_id"
                    disabled={!isEditable}
                    allowAdd={isEditable}
                    onChange={onMoveDestinationChange(move.data.position)}
                    onNewEntity={(prop, addr) => handleNewAddress(prop, addr, move.data.position)}
                  /> */}
                  <SelectInput
                    name="destination_id"
                    page={page}
                    value={move?.data?.destination_id}
                    options={addressOptions}
                    shouldHaveMargin={false}
                    handleChange={onMoveItemChange(move.data.position)}
                    dataCy="move_destination_id"
                    labelText=""
                    disabled={!isEditable}
                    multiple={false}
                    isClearable
                    isSearchable
                  />
                </td>
                <td data-cy={`${page}_move-table_action`} style={{ minWidth: '170px' }}>
                  <SelectInput
                    name="action"
                    page={page}
                    value={move?.data?.action}
                    options={ACTION_DROPDOWN_OPTIONS}
                    shouldHaveMargin={false}
                    handleChange={onMoveItemChange(move.data.position)}
                    dataCy="move_action"
                    labelText=""
                    disabled={!isEditable}
                    multiple={false}
                    isClearable
                    isSearchable
                  />
                </td>
                <td style={{ minWidth: '200px' }}>
                  <SelectInput
                    multiple
                    name="trailer_ids"
                    page={page}
                    value={move?.data?.trailer_ids}
                    options={fleetOptions}
                    shouldHaveMargin={false}
                    handleChange={onMoveItemChange(move.data.position)}
                    dataCy="move_trailer_id"
                    disabled={!isEditable}
                    labelText=""
                  />
                </td>
                <td data-cy="move-table_date" style={{ minWidth: '140px' }}>
                  {move.data.schedule_tbd ? (
                    <p>TBD</p>
                  ) : (
                    <DatePicker
                      id={`tripDetails-date__datePicker-${index}`}
                      showTimeSelect
                      selected={getMoveDate(move)}
                      dateFormat="dd/MM h:mm aa"
                      timeIntervals={15}
                      disabled={!isEditable}
                      data-cy={`${page}_moveTable-${index}-date_picker`}
                      onChange={(date: any) => onMoveItemDateChange(move.data.position)('scheduled_arrival_time', date)}
                    />
                  )}
                </td>
                <td style={{ maxWidth: '300px' }}>
                  <SelectInput
                    multiple
                    name="shipment_ids"
                    page={page}
                    value={move?.data?.shipment_ids}
                    options={shipmentIdsDropdownOptions}
                    shouldHaveMargin={false}
                    handleChange={onMoveItemChange(move.data.position)}
                    dataCy="move_shipment_id"
                    disabled={!isEditable}
                    labelText=""
                  />
                </td>
                <td style={{ maxWidth: '300px' }}>
                  <TimelinessBadge
                    scheduled={move.data.scheduled_arrival_time}
                    actual={move.data.actual_arrival_time}
                  />
                </td>
                {isEditable && (
                  <td>
                    <Button
                      id={`tripDetails-moves_open__btn-${index}`}
                      variant="link"
                      className="delete-btn"
                      onClick={() => handleSelectedMove(move)}
                      data-cy={`${page}_move-open_btn`}
                    >
                      {OPEN_TEXT}
                    </Button>
                  </td>
                )}
              </DraggableTableRow>
            ))}
          </DndProvider>
        </tbody>
        <div style={{ height: '150px' }} />
      </Table>
    </div>
  );
}
