/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { Descendant } from 'slate';

import { Move } from '../../../redux/models/trip.models';
import {
  ControlInput,
  InputSwitch,
  SelectInput,
  SelectInputWithSearchLocalData,
} from '../../../components/shared/Input';
import { DateService } from '../../../utils/dateService';
import { deleteApiMove, selectRoutesByTripNo } from '../../../redux/slices/trips/trips';
import { selectAddressesObject } from '../../../redux/slices/settings';
import { getSelectedOptions } from '../../../utils/core.utils';
import { ACTION_DROPDOWN_OPTIONS } from './constants/moves.constants';
import { DatePickerComponent as DatePicker } from '../../../components/shared/DatePicker';
import TimelinessBadge from '../../../components/timeliness/TimelinessBadge';
import TimelinessTooltip from '../../../components/timeliness/TimelinessTooltip';
import DispatchNotes from './components/DispatchNotes';
import { NoteTemplate } from '../../../redux/models/settings.models';
import MoveDocuments from './components/MoveDocuments';
import { DropdownOption } from './components/AssignDocModal.utils';
import { AppState } from '../../../redux/models/state.models';
import { defaultDispatchTemplate } from '../../../components/text/MoveNotes.utils';
import Analytics from '../../../utils/analytics';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';

interface Props {
  move: Move;
  routeId: string;
  addressOptions: any;
  fleetOptions: any;
  shipmentIdsDropdownOptions: DropdownOption[];
  noteTemplates: NoteTemplate[];
  onMoveItemChange: (movePosition: number) => (prop: string, value: any) => void;
  onMoveItemDateChange: (movePosition: number) => (prop: string, value: any) => void;
  onMoveDestinationChange: (movePosition: number) => (prop: string, value: string) => void;
  deleteMove: (positon: number) => void;
  setSelectedMove: React.Dispatch<React.SetStateAction<number | null>>;
}

const SHARE_URL_TEXT = 'Live Share Url';

function PanelRow({ title, children }: any) {
  return (
    <Row className="mt-2">
      <h6>{title || ''}</h6>
      {children}
    </Row>
  );
}

const getDefaultTemplate = (templates: NoteTemplate[]) => {
  const template = templates && templates.length ? templates[0] : null;
  return template?.data.notes || defaultDispatchTemplate;
}

/**
 * to do: add inputs to move panel
 * make move panel bigger
 * clean up appoint times if unknown or invalid
 * Show appt wording: Appt header - show on dispatch board
 */
export default function MovePanel({
  move,
  routeId,
  addressOptions,
  fleetOptions,
  shipmentIdsDropdownOptions,
  noteTemplates,
  onMoveItemChange,
  onMoveItemDateChange,
  onMoveDestinationChange,
  deleteMove,
  setSelectedMove,
}: Props) {
  const page = "move_panel";
  const route = useSelector((state: AppState) => selectRoutesByTripNo(state, routeId));
  const addresses = useSelector(selectAddressesObject);

  const stops = route?.stops || [];
  const documents = move?.data.documents || [];
  const notes = move?.data?.dispatch_notes || getDefaultTemplate(noteTemplates);
  const [dispatch, setDispatch] = useState<Descendant[]>(notes);
  const [isDeleting, setDelete] = useState(false);
  const isDocs = documents.length > 0;
  const moveStop = stops.find((stop: any) => {
    const destinationId = move.data.destination_id;
    if (!destinationId) {
      return false;
    }
    const destination = addresses[destinationId];
    if (!destination) {
      return false;
    }
    const stopId = stop?.address?.id || '';
    if (stopId === '') {
      return false;
    }
    const destSamsaraId = destination?.data?.samsara_id || '';
    return stopId === destSamsaraId;
  });

  const position = move.data.position || 0;
  const title = `Move #${position + 1}`;
  const arrival = move.data.actual_arrival_time || '';
  const departure = move.data.actual_departure_time || '';
  const waitTime = departure && arrival ? DateService.getDifferenceBetweenInHours(departure, arrival) : 0;
  
  const appt = move.data.show_appt || false;
  const scheduleTbd = move.data.schedule_tbd || false;

  const getMoveDate = (mv: Move, prop: string) => {
    const data: any = {
      ...mv.data,
    };
    const val = data[prop];
    if (!val) return null;
    return new Date(val);
  };

  const handleUpdateDispatch = (dispatchNotes: Descendant[]) => {
    // onMoveItemChange(move.data.position)('dispatch_notes', dispatchNotes);
    setDispatch(dispatchNotes);
  }

  const handleDelete = async () => {
    if (move.entity_id === '') {
      deleteMove(position);
      return;
    }
    // if entity_id delete
    try {
      console.log('delete from api');
      setDelete(true);
      await deleteApiMove(move)
    } catch (error) {
      Analytics.capture(error);
    } finally {
      setDelete(false);
      deleteMove(position);
    }
  }
  const handleSave = () => {
    onMoveItemChange(move.data.position)('dispatch_notes', dispatch);
    setSelectedMove(null);
  }
  const noAction = () => {};
  return (
    <Card>
      <Card.Body>
        <h4>{title}</h4>
        <PanelRow title="Route">
          <SelectInputWithSearchLocalData
            disabled={false}
            labelText=""
            name="destination_id"
            page={page}
            value={move?.data?.destination_id}
            options={getSelectedOptions(move?.data?.destination_id, addressOptions)}
            shouldHaveMargin={false}
            validDriverOptions={addressOptions}
            handleChange={onMoveDestinationChange(move.data.position)}
            dataCy="move_destination_id"
          />
        </PanelRow>
        <PanelRow title="Action">
          <SelectInput
            name="action"
            page={page}
            value={move?.data?.action}
            options={ACTION_DROPDOWN_OPTIONS}
            shouldHaveMargin={false}
            handleChange={onMoveItemChange(move.data.position)}
            dataCy="move_action"
            labelText=""
            disabled={false}
            multiple={false}
            isClearable
            isSearchable
          />
        </PanelRow>
        <PanelRow title="Trailer">
          <SelectInput
            multiple
            name="trailer_ids"
            page={page}
            value={move?.data?.trailer_ids}
            options={fleetOptions}
            shouldHaveMargin={false}
            handleChange={onMoveItemChange(move.data.position)}
            dataCy="move_trailer_id"
            disabled={false}
            labelText=""
          />
        </PanelRow>
        <PanelRow title="Schedule">
          <Row className="mt-2">
            <Col md="6">
              <h6 className="text-muted">Planned Arrival</h6>
              <DatePicker
                id="tripDetails-scheduled-arrival__datePicker"
                showTimeSelect
                selected={getMoveDate(move, 'scheduled_arrival_time')}
                dateFormat="dd/MM h:mm aa"
                timeIntervals={15}
                showMonthDropdown
                showYearDropdown
                disabled={scheduleTbd}
                onChange={(date: any) => onMoveItemDateChange(move.data.position)('scheduled_arrival_time', date)}
              />
            </Col>
            <Col md="6">
              <h6 className="text-muted">Planned Departure</h6>
              <DatePicker
                id="tripDetails-scheduled-departure__datePicker"
                showTimeSelect
                selected={getMoveDate(move, 'scheduled_departure_time')}
                dateFormat="dd/MM h:mm aa"
                timeIntervals={15}
                showMonthDropdown
                showYearDropdown
                disabled={scheduleTbd}
                onChange={(date: any) => onMoveItemDateChange(move.data.position)('scheduled_departure_time', date)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <h6 className="text-muted">Actual Arrival</h6>
              <DatePicker
                id="tripDetails-scheduled-arrival__datePicker"
                showTimeSelect
                selected={getMoveDate(move, 'actual_arrival_time')}
                dateFormat="dd/MM h:mm aa"
                timeIntervals={15}
                showMonthDropdown
                showYearDropdown
                disabled={scheduleTbd}
                onChange={(date: any) => onMoveItemDateChange(move.data.position)('actual_arrival_time', date)}
              />
            </Col>
            <Col md="6">
              <h6 className="text-muted">Actual Departure</h6>
              <DatePicker
                id="tripDetails-scheduled-departure__datePicker"
                showTimeSelect
                selected={getMoveDate(move, 'actual_departure_time')}
                dateFormat="dd/MM h:mm aa"
                timeIntervals={15}
                showMonthDropdown
                showYearDropdown
                disabled={scheduleTbd}
                onChange={(date: any) => onMoveItemDateChange(move.data.position)('actual_departure_time', date)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="text-muted">Move Timeliness</h6>
                <TimelinessTooltip />
              </div>
              <TimelinessBadge scheduled={move.data.scheduled_arrival_time} actual={move.data.actual_arrival_time} />
            </Col>
            <Col md="6">
              <h6 className="text-muted">Time At Stop</h6>
              <ControlInput
                name="move_timeliness"
                page={page}
                type="text"
                value={`${waitTime.toFixed(2)} hrs`}
                shouldHaveMargin={false}
                handleChange={noAction}
                dataCy="move_time_at_stop"
                disabled
                labelText=""
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={7}>
              <h6>Show Planned Arrival as Appointment</h6>
            </Col>
            <Col md={2} className="form-container-col">
              <InputSwitch
                labelText=""
                name="show_appt"
                page="appt"
                handleChange={onMoveItemChange(move.data.position)}
                checked={appt}
                className="form-toggle"
                dataCy="trip_appt"
                disabled={false}
              />
            </Col>
            <Col md={7}>
              <h6>Schedule TBD</h6>
            </Col>
            <Col md={2} className="form-container-col">
              <InputSwitch
                labelText=""
                name="schedule_tbd"
                page="trip_appt"
                handleChange={onMoveItemChange(move.data.position)}
                checked={scheduleTbd}
                className="form-toggle"
                dataCy="trip_appt_tbd"
                disabled={false}
              />
            </Col>
          </Row>
        </PanelRow>
        <PanelRow title="Shipments">
          <SelectInput
            multiple
            name="shipment_ids"
            page={page}
            value={move?.data?.shipment_ids}
            options={shipmentIdsDropdownOptions}
            shouldHaveMargin={false}
            handleChange={onMoveItemChange(move.data.position)}
            dataCy="move_shipment_id"
            disabled={false}
            labelText=""
          />
        </PanelRow>
        <PanelRow title="Temp (f)">
          <ControlInput
            name="temp"
            page={page}
            type="number"
            value={move?.data?.temp}
            shouldHaveMargin={false}
            handleChange={onMoveItemChange(move.data.position)}
            dataCy="move_temp"
            disabled={false}
            labelText=""
          />
        </PanelRow>
        <PanelRow title="Dispatch Notes">
          <DispatchNotes initialNotes={notes} handleUpdateDispatch={handleUpdateDispatch} />
        </PanelRow>
        {isDocs && (
          <PanelRow title="Documents">
            <MoveDocuments
              documents={documents}
              shipmentIdsDropdownOptions={shipmentIdsDropdownOptions}
            />
          </PanelRow>
        )}
        {
          moveStop?.liveSharingUrl ? (
            <Row className="mt-3 mx-1">
              <PanelRow title="Samsara">
                <Button variant="outline-secondary" href={moveStop?.liveSharingUrl} target="_blank">
                  {SHARE_URL_TEXT}
                </Button>
              </PanelRow>
            </Row>
          ) : null
        }
        <Row className="mt-4">
          <Stack direction="horizontal" gap={3}>
            <AsyncButton
              title="Delete"
              disabled={isDeleting}
              id="tripDetails_move-delete_btn"
              dataCy="tripDetails_move-delete_btn"
              variant="outline-danger"
              spinner="danger"
              handleClick={handleDelete}
            />
            <Button variant="outline-primary ms-auto float-right" onClick={handleSave}>Save</Button>
          </Stack>
        </Row>
      </Card.Body>
    </Card>
  )
}
