/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { DateService } from '../../../utils/dateService';
import { ControlInput, SelectInput } from '../../shared/Input';
import {
  selectAddresses, selectBillingTypes, selectContacts, selectShipmentTypes,
} from '../../../redux/slices/settings';
import { createDropdownOptions } from '../../../utils/core.utils';
import { SHIPMENT_STATUS_TYPES } from '../../../pages/shipments/constants/shipments.constants';
import { DateRangeType, FILTER_TEXT } from '../SearchFilter/searchFilters.constants';
import { ShipmentListFilters, defaultShipmentListFilters } from '../../../redux/models/shipment.models';
import DateButton from '../../shared/DateButton';
import { SearchDateRange } from '../SearchFilter/searchFilter.models';

const TRIP_DATES = 'Trip Dates';
const APPLY = 'Apply';
const CLEAR = 'Clear';
const PAGE = 'shipment_list';

const getDateInputValue = (date: string) => {
  return DateService.getYYYYMMDDFormat(date);
};

interface Props {
  filterData: ShipmentListFilters,
  shouldShowFilters: boolean;
  isDateBtnOpen: boolean;
  handleDateBtnToggle: (open: boolean) => void;
  saveFilters: (filters: ShipmentListFilters) => void;
  setShouldShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDateBtnOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ShipmentsModalFilter({
  filterData,
  shouldShowFilters,
  isDateBtnOpen,
  handleDateBtnToggle,
  setIsDateBtnOpen,
  setShouldShowFilters,
  saveFilters,
}: Props) {
  const [filters, setFilters] = useState(filterData);
  const shipmentTypes = useSelector(selectShipmentTypes);
  const contacts = useSelector(selectContacts);
  const shipmentTypeEntityOptions = createDropdownOptions(shipmentTypes);
  const customerSelectOptions = createDropdownOptions(contacts);
  const addresses = useSelector(selectAddresses);
  const addressSelectOptions = createDropdownOptions(addresses, 'samsara_name');
  const statusTypeOptions = Object.values(SHIPMENT_STATUS_TYPES);
  const billingTypes = useSelector(selectBillingTypes);
  const [dateType, setDateType] = useState(DateRangeType.Custom);
  const customerBillingTypes = billingTypes?.filter((billingType) => {
    return billingType.data?.customer_id === filters.customer;
  }) || [];
  const billingTypeSelectOptions = createDropdownOptions(customerBillingTypes);

  const handleFilterChange = (name: string, value: any) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDateFilterChange = (name: string, value: any) => {
    if (dateType !== DateRangeType.Custom) setDateType(DateRangeType.Custom);
    const isStart = name === 'dateFrom';
    const val = isStart ? DateService.getStartOfTheDayISO(value) : DateService.getEndOfTheDayISO(value);
    setFilters({
      ...filters,
      [name]: DateService.getUtcISOString(val),
    });
  };

  const changeDateRangeFilter = (selectedDate: SearchDateRange, type: DateRangeType) => {
    setDateType(type);
    setFilters({
      ...filters,
      dateFrom: selectedDate.dateFrom,
      dateTo: selectedDate.dateTo,
    });
  };

  const onApplyClick = () => {
    const { dateFrom, dateTo } = filters;
    if (dateFrom > dateTo) {
      toast('Date from must be earlier than date to.', { type: 'error' });
      return;
    }
    setShouldShowFilters(false);
    const updates: ShipmentListFilters = {
      ...filters,
      dateFrom: dateFrom ? DateService.getISOString(dateFrom) : '',
      dateTo: dateTo ? DateService.getISOString(dateTo) : '',
    };
    saveFilters(updates);
  };

  const onClearClick = () => {
    setFilters(defaultShipmentListFilters);
    // setDateRange(DEFAULT_DATE_RANGE);
  };

  return (
    <Card className={`search-filter-wrapper ${shouldShowFilters ? '' : 'hidden'}`}>
      <Card.Body>
        <h4>{FILTER_TEXT}</h4>
        <Row>
          <Col md={6}>
            <SelectInput
              labelText="Customer"
              name="customer"
              value={filters.customer || ''}
              options={customerSelectOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_customer_id"
            />
          </Col>
          <Col md={6}>
            <SelectInput
              labelText="Quote Template"
              name="loadType"
              value={filters.loadType || ''}
              options={billingTypeSelectOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_load_type"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <SelectInput
              labelText="Shipment Type"
              name="shipmentTypeId"
              value={filters.shipmentTypeId || ''}
              options={shipmentTypeEntityOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_shipment_type"
            />
          </Col>
          <Col md={6}>
            <SelectInput
              labelText="Status"
              name="status"
              value={filters.status || ''}
              options={statusTypeOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_status"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <SelectInput
              labelText="Origin"
              name="origin"
              value={filters.origin || ''}
              options={addressSelectOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_origin_id"
            />
          </Col>
          <Col md={6}>
            <SelectInput
              labelText="Destination"
              name="destination"
              value={filters.destination || ''}
              options={addressSelectOptions}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_destination_id"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControlInput
              labelText="Container #"
              name="containerNo"
              value={filters.containerNo || ''}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_container_no"
            />
          </Col>
          <Col md={6}>
            <ControlInput
              labelText="P.O. #"
              name="purchaseOrder"
              value={filters.purchaseOrder || ''}
              handleChange={handleFilterChange}
              page="trips_shipment_modal"
              dataCy="shipment_modal_purchase_order_no"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5>{TRIP_DATES}</h5>
            <DateButton
              type={dateType}
              range={filters}
              page={PAGE}
              isOpen={isDateBtnOpen}
              setIsOpen={setIsDateBtnOpen}
              handleToggle={handleDateBtnToggle}
              setDateFilterParams={changeDateRangeFilter}
            />
          </Col>
          <Col md={6}>
            <ControlInput
              labelText="From"
              name="dateFrom"
              page={PAGE}
              type="date"
              value={getDateInputValue(filters.dateFrom)}
              handleChange={handleDateFilterChange}
              dataCy="shipment_modal_date_from"
            />
          </Col>
          <Col md={6}>
          <ControlInput
              labelText="To"
              name="dateTo"
              page={PAGE}
              type="date"
              value={getDateInputValue(filters.dateTo)}
              handleChange={handleDateFilterChange}
              dataCy="shipment_modal_date_to"
            />
          </Col>
        </Row>
        <div className="mt-2 d-flex justify-content-end">
          <Stack direction="horizontal" gap={2}>
            <Button variant="secondary" data-cy="shipment_modal_close_btn" onClick={onClearClick}>
              {CLEAR}
            </Button>
            <Button
              id="search_shipments_table_apply_filter_btn"
              data-cy="search_shipments_table_apply_filter_btn"
              variant="primary"
              onClick={onApplyClick}
            >
              {APPLY}
            </Button>
          </Stack>
        </div>
      </Card.Body>
    </Card>
  );
}
