import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { Form, FormLabel } from 'react-bootstrap';
import { createDropdownOptions, debounce } from '../../utils/core.utils';
import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';
import Analytics from '../../utils/analytics';

export function ControlInput({
  labelText,
  name,
  page,
  value = '',
  type = 'text',
  shouldHaveMargin = true,
  handleChange,
  dataCy,
  disabled = false,
}) {
  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText && (
        <FormLabel>
          <h5>{labelText}</h5>
        </FormLabel>
      )}
      <Form.Control
        id={`${page}-${name}__input`}
        className={name}
        name={name}
        value={value || ''}
        type={type}
        onChange={(e) => handleChange(name, e.target.value)}
        style={{ padding: '7px 9px', borderRadius: '4px' }}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export function DateInput({
  labelText,
  name,
  page,
  value = '',
  shouldHaveMargin = true,
  handleChange,
  dataCy,
  max,
  min,
  disabled = false,
}) {
  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText && (
        <FormLabel>
          <h5>{labelText}</h5>
        </FormLabel>
      )}
      <Form.Control
        id={`${page}-${name}__input`}
        data-cy={`${page}-${name}__input`}
        className={name}
        name={name}
        value={value || ''}
        type="date"
        max={max}
        min={min}
        onChange={(e) => handleChange(name, e.target.value)}
        style={{ padding: '7px 9px', borderRadius: '4px' }}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export function ControlTextArea({
  labelText,
  name,
  page,
  value = '',
  shouldHaveMargin = true,
  handleChange,
  dataCy,
  disabled = false,
  rows,
}) {
  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText && (
        <FormLabel>
          <h5>{labelText}</h5>
        </FormLabel>
      )}
      <Form.Control
        id={`${page}-${name}__input`}
        className={name}
        name={name}
        value={value || ''}
        as="textarea"
        rows={rows}
        onChange={(e) => handleChange(name, e.target.value)}
        style={{ padding: '7px 9px', borderRadius: '4px' }}
        disabled={disabled}
      />
    </Form.Group>
  );
}

function SelectDetailLabel({ label, detail }) {
  return (
    <div>
      <div>{label}</div>
      {detail && (
        <small style={{ color: '#888' }}>{detail}</small>
      )}
    </div>
  );
};

export function SelectInput({
  labelText = '',
  placeholder = 'Search...',
  disabled = false,
  name,
  page,
  value,
  options = [],
  shouldHaveMargin = true,
  multiple = false,
  isClearable = true,
  isSearchable = true,
  handleChange,
  dataCy,
}) {
  const onChange = multiple
    ? (selectValue) => handleChange(name, [].slice.call(selectValue).map((item) => item.value))
    : (valueObj) => handleChange(name, valueObj ? valueObj.value : '');

  const optionValues = options.map((option) => option.value);
  const found = optionValues.filter((option) => value?.includes(option));

  const selectValue = multiple
    ? found.map((id) => options.find((option) => option.value === id))
    : options.filter((option) => option.value === value);

  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText && (
        <FormLabel>
          <h5>{labelText}</h5>
        </FormLabel>
      )}
      <Select
        id={`${page}-${name}__select`}
        instanceId={` ${page}-${name}`}
        inputId={`${page}-${name}__input`}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isMulti={multiple}
        options={options}
        placeholder={placeholder}
        value={selectValue}
        formatOptionLabel={SelectDetailLabel}
        onChange={onChange}
      />
    </Form.Group>
  );
}

export function SelectInputWithSearch({
  disabled,
  labelText,
  name,
  page,
  value,
  options = [],
  emptyStateOptions = [],
  shouldHaveMargin = true,
  isClearable = true,
  settingName,
  nameKeyForDropdownOptions = 'name',
  apiQueryParam = 'samsara_name',
  handleChange,
  dataCy,
}) {
  const selectOptions = value === '' && emptyStateOptions.length ? emptyStateOptions : options;
  const [dropdownOptions, setDropdownOptions] = useState(selectOptions);
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    setDropdownOptions(selectOptions);
  }, [selectOptions]);

  const onInputChange = async (searchValue) => {
    if (!searchValue) {
      return false;
    }
    try {
      setIsSearching(true);
      const { data: searchSettings } = await AquadashServiceApiProvider.searchSettings(
        settingName,
        searchValue,
        apiQueryParam,
      );
      return setDropdownOptions(createDropdownOptions(searchSettings.data, nameKeyForDropdownOptions));
    } catch (e) {
      return Analytics.capture(e);
    } finally {
      setIsSearching(false);
    }
  };

  const optimizedSearch = useCallback(debounce(onInputChange), []);

  const onChange = (valueObj) => handleChange(name, valueObj ? valueObj.value : '');

  const selectValue = dropdownOptions.filter((option) => option.value === value);

  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText ? (
        <FormLabel>
          <h5>
            {labelText}
          </h5>
        </FormLabel>
      ) : null}
      <Select
        id={`${page}-${name}__select`}
        instanceId={` ${page}-${name}`}
        inputId={`${page}-${name}__input`}
        isSearchable
        isDisabled={disabled}
        isLoading={isSearching}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        isClearable={isClearable}
        options={dropdownOptions}
        value={selectValue}
        placeholder="Type..."
        onChange={onChange}
        onInputChange={optimizedSearch}
      />
    </Form.Group>
  );
}

export function SelectInputWithSearchLocalData({
  disabled,
  labelText,
  name,
  page,
  value,
  options = [],
  shouldHaveMargin = true,
  isClearable = true,
  handleChange,
  validDriverOptions,
  dataCy,
}) {
  const [dropdownOptions, setDropdownOptions] = useState(validDriverOptions);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setDropdownOptions(validDriverOptions);
  }, [options]);

  const createLocalDropdownOptions = (items) => items.sort((a, b) => a?.label?.localeCompare(b?.label));

  const onInputChange = (searchValue) => {
    if (!searchValue) setIsSearching(false);
    if (validDriverOptions.length === 0) setIsSearching(true);
    if (validDriverOptions.length >= 1) setIsSearching(false);
    setDropdownOptions(createLocalDropdownOptions(validDriverOptions));
  };

  const onChange = (valueObj) => handleChange(name, valueObj ? valueObj.value : '');

  const selectValue = dropdownOptions.filter((option) => (option.value === value ? option.label : null));

  return (
    <Form.Group data-cy={dataCy} className={`${shouldHaveMargin ? 'mb-3' : ''}`}>
      {labelText && (
        <FormLabel>
          <h5>
            {labelText}
          </h5>
        </FormLabel>
      )}
      <Select
        id={`${page}-${name}__select`}
        instanceId={` ${page}-${name}`}
        inputId={`${page}-${name}__input`}
        isSearchable
        isDisabled={disabled}
        isLoading={isSearching}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        isClearable={isClearable}
        options={dropdownOptions}
        value={selectValue}
        placeholder="Type..."
        onChange={onChange}
        onInputChange={onInputChange}
      />
    </Form.Group>
  );
}

export function InputSwitch({
  labelText,
  name,
  page,
  handleChange,
  checked,
  dataCy,
  className,
  disabled,
}) {
  const onChange = (e) => handleChange(name, e.target.checked);

  return (
    <Form.Group data-cy={dataCy} className={className}>
      <Form.Check
        type="switch"
        id={`${page}-${name}__switch`}
        label={labelText}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </Form.Group>
  );
}
