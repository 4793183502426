import { AxiosInstance } from 'axios';
import { EntityEndpointProvider } from './EntityEndpointProvider';

interface RequestProps {
  start_date: string;
  end_date: string;
  page?: number;
  page_size?: number;
}

export class DashboardServiceProvider {
  private endpointProvider: EntityEndpointProvider;

  private httpProvider: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.endpointProvider = new EntityEndpointProvider('dashboard');
    this.httpProvider = axiosInstance;
  }
  
  get api() {
    return this.httpProvider;
  }

  get endpoint() {
    return this.endpointProvider;
  }

  moveTimeliness(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/moves/timeliness`, data);
  }

  moveAppointments(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/moves/appointments`, data);
  }

  moveBobtails(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/moves/bobtails`, data);
  }

  moveEmpties(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/moves/empty`, data);
  }

  tripTotals(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/trips/total`, data);
  }

  tripsOutsideCartage(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/trips/outside-cartage`, data);
  }

  shipmentRevenue(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/shipments/revenue`, data);
  }

  shipmentsInvoiced(data: RequestProps) {
    return this.api.post(`${this.endpoint.entityPath()}/shipments/invoiced`, data);
  }

  shipmentsRequested(page: number) {
    return this.api.post(`${this.endpoint.entityPath()}/shipments/requested`, { page, page_size: 5 });
  }
}
