/* eslint-disable import/no-cycle */
import { Link } from "react-router-dom";
import { toast, TypeOptions } from "react-toastify";
import { AppNotificationData } from "../../redux/slices/notifications/notifications.models";
import { AsyncState } from "../../services/notifications/Notification.models";
import DispatchRequestButton from "./DispatchRequestButton";

const getRoute = (notification: AppNotificationData) => {
  const entityId = notification.entity_id || '';
  const type = notification.entity_type || '';
  if (type === 'trip') return `trips/${entityId}`;
  if (type === 'board') return `boards/${entityId}`;
  if (type === 'shipment') return `shipments/${entityId}`;
  return `${type}/${entityId}`;
};

const getToastType = (state: string) => {
  const { DEFAULT, SUCCESS, ERROR, INFO } = toast.TYPE;
  if (state === AsyncState.QUEUED) return INFO;
  if (state === AsyncState.SUCCESS) return SUCCESS;
  if (state === AsyncState.ERROR) return ERROR;
  return DEFAULT;
}

export const successToast = (message: string) => toast(message, { type: 'success' });
export const infoToast = (message: string) => toast(message, { type: 'info' });
export const errorToast = (message: string) => toast(message, { type: 'error' });
export const routeToast = (message: string, btn: string, route: string, type:  TypeOptions = 'success') => {
  toast((
    <div>
      {`${message}. `}
      <Link to={`${route}`}>
        <span>{`${btn}`}</span>
      </Link>
    </div>
  ), { type });
};
export const multiDispatchToast = (
  message: string, requestId: string, type: TypeOptions = 'success'
) => {
  toast((
    <div>
      {`${message}. `}
      <DispatchRequestButton requestId={requestId} />
    </div>
  ), { type });
};
export const notificationToast = (notification: AppNotificationData, org: string) => {
  const title = notification.title || '';
  const state = notification.state || '';
  const entity = notification.entity_type || '';
  const route = getRoute(notification);
  routeToast(`${title}. `, `Open ${entity}`, `/${org}/${route}`, getToastType(state));
  // toast((
  //   <div>
  //     {`${title}. `}
  //     <Link to={`/${org}/${route}`}>
  //       <span>{`Open ${entity}`}</span>
  //     </Link>
  //   </div>
  // ), { type: getToastType(state) });
};
