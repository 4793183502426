/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';

import TrailerChangeAlert from './TrailerChangeAlert';
import {
  TrailerChangeSaveModal,
  TrailerChangeModal,
} from '../../../components/modals/TrailerChangeModal/TrailerChangeModal';

import { selectFleetObject } from '../../../redux/slices/settings';
import {
  selectInitialMove,
} from '../../../redux/slices/trailerChanges';

const TRAILER_CHANGE_ID = 'trailerChanges';

export default function MoveFooter({
  page,
  showTrailerSaveModal,
  handleSave,
  showTrailerChangeModal,
  isSaving,
  shouldDisableSave,
  handleModalClose,
  handleTrailerChangeSave,
  handleChangeModals,
  trailerChangeTrips,
  fleetLocations,
  showTrailers,
}: any) {
  const fleet = useSelector(selectFleetObject);
  const initialMoves = useSelector(selectInitialMove);

  return (
    <>
      {showTrailers && (
        <TrailerChangeAlert
          page={page}
          name={TRAILER_CHANGE_ID}
          handleChangeModals={handleChangeModals}
          initialMoves={initialMoves}
          fleet={fleet}
        />
      )}
      <TrailerChangeModal
        page={page}
        name={TRAILER_CHANGE_ID}
        showTrailerChangeModal={showTrailerChangeModal}
        handleModalClose={handleModalClose}
        fleet={fleet}
        initialMoves={initialMoves}
        handleChangeModals={handleChangeModals}
        trips={trailerChangeTrips}
        shouldDisableSave={shouldDisableSave}
        handleTrailerChangeSave={handleTrailerChangeSave}
        isSaving={isSaving}
        fleetLocations={fleetLocations}
      />
      <TrailerChangeSaveModal
        page={page}
        name={TRAILER_CHANGE_ID}
        showTrailerSaveModal={showTrailerSaveModal}
        handleChangeModals={handleChangeModals}
        handleSave={handleSave}
        fleet={fleet}
        initialMoves={initialMoves}
        handleModalClose={handleModalClose}
        shouldDisableSave={shouldDisableSave}
        isSaving={isSaving}
      />
    </>
  );
}
