import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
 Card, Dropdown, DropdownButton, Row, Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  selectAddressesObject,
  selectBillingTypeObject,
  selectContactsObject,
} from '../../../redux/slices/settings';
import { saveTripDetails, selectIsTripDirty } from '../../../redux/slices/trips/trips';

import './ShipmentCard.scss';
import { DangerousGoodsIcon, DryIcon } from '../../icons/ShipmentIcons';
import Analytics from '../../../utils/analytics';
import { Shipment } from '../../../redux/models/shipment.models';
import { TripDetails } from '../../../redux/models/trip.models';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import ShipmentCardStatus from './ShipmentCardStatus';

const SHOW_TRIPS = 'Show trips';
// const OPEN_TEXT = 'Open shipment';
const REMOVE_TEXT = 'Remove';

interface Props {
  page: string;
  shipment: Shipment;
  tripDetails?: TripDetails
  dataCy: string;
  isEditable: boolean;
  onRemoveShipment: (shipmentId: string) => void;
  selectShipment?: (shipment: Shipment) => void;
}

function ShipmentCard({
  page,
  shipment,
  isEditable,
  selectShipment,
  tripDetails,
  onRemoveShipment,
  dataCy,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const isDirty = useSelector(selectIsTripDirty);
  const contactsObject = useSelector(selectContactsObject);
  const addressesObject = useSelector(selectAddressesObject);
  const billingTypesObject = useSelector(selectBillingTypeObject);
  const [isSaving, setIsSaving] = useState(false);
  const { shipment_no: shipmentNo } = shipment.data;
  const shipmentId = shipment.entity_id;
  const containerNo = shipment.data.container_no || '';
  const isContainer = containerNo !== '';
  const customer = contactsObject[shipment.data?.customer_id]?.data?.name;
  const origin = addressesObject[shipment.data?.origin_id]?.data?.samsara_name;
  const destination = addressesObject[shipment.data?.destination_id]?.data?.samsara_name;
  const details = billingTypesObject[shipment.data?.billing_type_id]?.data?.name;

  const openShipmentPage = async () => {
    try {
      if (tripDetails && isEditable && isDirty) {
        setIsSaving(true);
        await saveTripDetails(tripDetails);
      }
      navigate(`/${orgCode}/shipments/${shipmentId}`);
    } catch (error) {
      if (error instanceof Error) {
        Analytics.capture(error);
        toast(error.message, { type: 'error' });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card data-cy={dataCy}>
      <Card.Body>
        <Row className="d-flex align-items-baseline justify-content-between">
          <Col className="d-flex flow-row">
            {/* <h5>{shipmentNo}</h5> */}
            <AsyncButton
              title={shipmentNo}
              id={`${page}-shipments_openShipments__btn`}
              dataCy="openShipments_btn"
              variant="light"
              spinner="secondary"
              disabled={isSaving}
              // size="sm"
              handleClick={openShipmentPage}
            />
            {shipment.data.dangerous_goods && <h6 className="shipment-card-icon-container"><DangerousGoodsIcon /></h6>}
            {shipment.data.dry && (
              <h6 className="shipment-card-icon-container">
                <DryIcon />
              </h6>
            )}
          </Col>
          {isEditable && (
            <Col style={{ textAlign: 'right' }} xs={3}>
              <DropdownButton
                className="caret"
                variant="link"
                id={`${page}-shipments__dropdown-${shipmentId}`}
                title="•••"
                data-cy="shipment_card_dropdown"
              >
                {tripDetails && (
                  <Dropdown.Item
                    data-cy={`${page}-shipments_showTrips__btn`}
                    onClick={() => {
                      if (selectShipment) {
                        selectShipment(shipment)
                      }
                    }}
                  >
                    {SHOW_TRIPS}
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  data-cy="removeShipment_btn"
                  onClick={() => onRemoveShipment(shipmentId)}
                >
                  {REMOVE_TEXT}
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          )}
        </Row>
        <Row>
          <p className="bold-text">{details}</p>
          <Col className="shipment-card-col">{customer || 'Customer'}</Col>
          {shipment.data.status && <ShipmentCardStatus status={shipment.data.status} />}
          {isContainer && (
            <p style={{ minHeight: '45px' }}>{containerNo}</p>
          )}
          {!isContainer && (
            <p style={{ minHeight: '45px' }}>{`${origin || 'Origin'} >> ${destination || 'Destination'}`}</p>
          )}
          {!shipment.data.dry && shipment.data.temp && (
            <p>
              Temp: {`${shipment.data.temp}°F`}
            </p>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

ShipmentCard.defaultProps = {
  tripDetails: null,
  selectShipment: () => {},
}

export default ShipmentCard;