/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Row, Tooltip, OverlayTrigger, Stack, Dropdown, ButtonGroup,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setFilterParams, selectTripsFilterParams } from '../../redux/slices/trips/trips';
import { createBoardShipment, createBoardTrip } from '../../redux/slices/shipments';
import { boardShipment, boardTrip, FILTER_KEYS } from './constants/boards.constants';
import { SearchDateRange } from '../../components/modals/SearchFilter/searchFilter.models';
import { Shipment } from '../../redux/models/shipment.models';
import {
  saveFilterValueToSessionStorage,
  getFilterValueFromSessionStorage,
} from '../../utils/core.utils';
import { DateRangeType } from '../../components/modals/SearchFilter/searchFilters.constants';

import { Board } from '../../redux/models/board.models';
import { resetPlans } from '../../redux/slices/planner/planner';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import BoardMenu from './components/BoardMenu';
import { Trip } from '../../redux/models/trip.models';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';
import BoardDateMenu from './components/BoardDateMenu';
import { DateService } from '../../utils/dateService';

const BUTTON_TEXT = 'Add';
const TOOLTIP_TEXT = 'Integrations';

function getBtnText(filters: SearchDateRange) {
  // const rangeType = filters.dateType || DateRangeType.Custom;
  // if (rangeType !== DateRangeType.Custom) {
  //   return rangeType;
  // }
  const start = DateService.getDoMMMFormat(filters.dateFrom);
  const end = DateService.getDoMMMFormat(filters.dateTo);
  return `${start} - ${end}`;
};

interface Props {
  board: Board;
  integrations: string[];
  shouldShowIntegrationsMenu: boolean;
  setShouldShowIntegrationsMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

function Header({
  board,
  integrations,
  shouldShowIntegrationsMenu,
  setShouldShowIntegrationsMenu,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const filterParams: SearchDateRange = useSelector(selectTripsFilterParams);

  const boardId = board?.entity_id;
  const noteTemplates = board?.data.note_templates || [];
  const noteTemplate = noteTemplates && noteTemplates.length > 0 ? noteTemplates[0] : null;
  const noteTemplateId = noteTemplate?.entity_id;
  const defaultNotes = noteTemplate?.data.notes || [];

  const openShipmentPage = (shipmentId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/shipments/${shipmentId}`);
  }
  const openTripPage = (tripId: string) => {
    dispatch(resetShipments());
    navigate(`/${orgCode}/trips/${tripId}`);
  }

  const handleAddTrip = async () => {
    try {
      const defaultData: any = boardTrip(filterParams.dateFrom, boardId, '', '', [], defaultNotes);
      const trip: Trip = await createBoardTrip(defaultData, orgCode);
      if (trip.entity_id) {
        openTripPage(trip.entity_id);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const handleAddTripShipment = async () => {
    try {
      const defaultData: any = boardShipment(filterParams.dateFrom, boardId, '', '', [], noteTemplateId, defaultNotes);
      const shipment: Shipment = await createBoardShipment(defaultData, orgCode);
      if (shipment.entity_id) {
        openShipmentPage(shipment.entity_id);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add trip. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {TOOLTIP_TEXT}
    </Tooltip>
  );

  useEffect(() => {
    const dateFromParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_FROM);
    const dateToParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TO);
    const dateTypeParams = getFilterValueFromSessionStorage(FILTER_KEYS.DATE_TYPE);
    if (dateFromParams && dateToParams && dateTypeParams) {
      dispatch(
        setFilterParams({
          ...filterParams,
          dateTo: dateToParams,
          dateFrom: dateFromParams,
        }),
      );
    }
  }, []);

  const changeDateFilter = (dateRange: SearchDateRange) => {
    saveFilterValueToSessionStorage(
      FILTER_KEYS.DATE_FROM,
      dateRange.dateFrom,
    );
    saveFilterValueToSessionStorage(
      FILTER_KEYS.DATE_TO,
      dateRange.dateTo,
    );
    saveFilterValueToSessionStorage(
      FILTER_KEYS.DATE_TYPE,
      dateRange.dateType || DateRangeType.Custom,
    );
    dispatch(
      setFilterParams({
        ...filterParams,
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        dateType: dateRange.dateType,
      }),
    );
    dispatch(resetPlans());
  };
  return (
    <Row className="mb-2">
      <Stack direction='horizontal' gap={4}>
        {board && <BoardMenu board={board} />}
        <div className="ms-auto pt-2">
          <Button
            variant="outline-secondary"
            data-cy="dispatch_board_filter_btn"
            className="filter-datepicker d-flex align-items-center"
            onClick={() => setIsDateMenuOpen(!isDateMenuOpen)}
          >
            <Stack gap={1} direction="horizontal">
              {getBtnText(filterParams)}
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={isDateMenuOpen ? faChevronUp : faChevronDown}
              />
            </Stack>
          </Button>
          <BoardDateMenu
            defaultFromDate={filterParams.dateFrom}
            defaultToDate={filterParams.dateTo}
            shouldShowFilters={isDateMenuOpen}
            setShouldShowFilters={setIsDateMenuOpen}
            saveFilters={changeDateFilter}
          />
        </div>
        <Dropdown as={ButtonGroup}>
          <AsyncButton
            id="boards-add__btn"
            dataCy="boards-add_btn_menu"
            spinner="light"
            title={BUTTON_TEXT}
            handleClick={handleAddTrip}
          />
          <Dropdown.Toggle split variant="primary" id="add-trip-dropdown" data-cy="boards-add_option_btn" />
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleAddTrip} data-cy="boards-add_trip_btn">Trip</Dropdown.Item>
            <Dropdown.Item onClick={handleAddTripShipment} data-cy="boards-add__btn">Trip & Shipment</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {!shouldShowIntegrationsMenu && integrations.length ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltip}
          >
            <div>
              <FontAwesomeIcon
                id="boards-integrations_toggle-Open__btn"
                size="lg"
                icon={faPaperPlane}
                className="integrations-menu-toggle ms-2"
                onClick={() => setShouldShowIntegrationsMenu(!shouldShowIntegrationsMenu)}
              />
            </div>
          </OverlayTrigger>
        ) : null}
      </Stack>
    </Row>
  );
}

export default Header;
