/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DataModel<T> {
  author: string;
  data: T;
  entity_id: string;
  entity_type: string;
  modified_at?: string;
  created_at?: string;
  roles?: string[];
  version: string;
}

export interface EntityContainer<T> {
  [index: string]: T;
};

export type ExternalIds = EntityContainer<string>;

export interface ChangeLogItem {
  new: any;
  old: any;
}

export interface ChangeLog {
  author: string;
  changes: EntityContainer<ChangeLogItem>;
  event_type: string;
  modified_at: string;
}

export function createContainer<T>(data: DataModel<T>[]): EntityContainer<DataModel<T>> {
  return data.reduce((dir: EntityContainer<DataModel<T>>, item: DataModel<T>) => {
    return {
      ...dir,
      [item.entity_id]: item,
    };
  }, {});
};

export function getDefaultDataModel<T>(data: T): DataModel<T> {
  return {
    author: '',
    data,
    entity_id: '',
    entity_type: '',
    version: '',
  }
};
