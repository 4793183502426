import { createSlice } from '@reduxjs/toolkit';
import { DriverAvailabilityApi } from "../../../services/DriverAvailabilityApi";
import { AvailabilityApi } from "../../../services/services";
import Analytics from "../../../utils/analytics";
import { DateService } from "../../../utils/dateService";
import { ApiQuery, QueryFilter, QueryOperator, FilterOp, ElementType } from "../../models/network.models";
import { getInitialState } from "./driver.availability.models";
import { DRIVER_AVAILABILITY } from "../slices";
import {
  AppState,
  DispatchProps,
} from '../../models/state.models';
import { getError } from './driver.availability.utils';

const { DateTime, Number } = ElementType;
const { Equals } = FilterOp;

/**
 * TODO:
 * - Create boilerplate trip plan entity and endpoints
 * - Query get trip plans for date
 */
const SLICE_NAME = DRIVER_AVAILABILITY;
const initialState = getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAvailability(state, action) {
      state.availability = action.payload;
    },
    setIsAvailabilityLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsAvailabilityLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    resetAvailability: () => getInitialState(),
  },
});

export const {
  setAvailability,
  setIsAvailabilityLoading,
  setIsAvailabilityLoaded,
  resetAvailability,
} = slice.actions;

export const updateDriverTrip = async () => { // newDriverId: string, oldDriverId: string, tripId: string
  try {
    // if (oldDriverId !== newDriverId && oldDriverId !== '') {
    //   console.log('remove driver trip');
    //   await DriverAvailabilityApi.removeDriverTrip(oldDriverId, tripId);
    // }
    // if (newDriverId !== '') {
    //   const { data: { data } } = await DriverAvailabilityApi.addDriverTrip(newDriverId, tripId);
    //   console.log(data);
    // }
  } catch (error) {
    Analytics.capture(error);
    throw error;
  }
}

export const getConflictingDriverTrips = async (departureTime: string, arrivalTime: string, driverId: string, ) => {
  const startOfDay = DateService.getStartOfTheDayISO(departureTime);
  const endOfDay = DateService.getEndOfTheDayISO(arrivalTime);
  console.log(endOfDay);
  const filters = {
    "filters": [
      {
        "field": {
          "name": "data.scheduled_departure_time",
          "type": DateTime
        },
        "op": FilterOp.GreaterThanOrEqual,
        "value": startOfDay,
      },
      {
        "field": {
          "name": "data.scheduled_departure_time",
          "type": DateTime
        },
        "op": FilterOp.LessThanOrEqual,
        "value": endOfDay,
      },
      {
        "field": {
          "name": "data.scheduled_arrival_time",
          "type": DateTime,
        },
        "op": FilterOp.GreaterThanOrEqual,
        "value": startOfDay,
      },
      {
        "field": {
          "name": "data.scheduled_arrival_time",
          "type": ElementType.DateTime,
        },
        "op": FilterOp.LessThanOrEqual,
        "value": endOfDay,
      },
      {
        "field": {
          "name": "data.driver_id",
          "type": ElementType.Rel
        },
        "op": FilterOp.Equals,
        "value": driverId
      }
    ],
    "operator": QueryOperator.And
  };
  return DriverAvailabilityApi.findDriverTrips(filters);
}


export const getDriverTrips = async (departureTime: string, arrivalTime: string, driverIds: string[]) => {
  const startOfDay = DateService.getStartOfTheDayISO(departureTime);
  const endOfDay = DateService.getEndOfTheDayISO(arrivalTime);
  console.log(driverIds);
  const filters: ApiQuery = {
    "filters": [
      {
        "field": {
          "name": "data.scheduled_departure_time",
          "type": DateTime
        },
        "op": "gte",
        "value": DateService.getUtcISOString(startOfDay)
      },
      {
        "field": {
          "name": "data.scheduled_departure_time",
          "type": DateTime
        },
        "op": "lte",
        "value": DateService.getUtcISOString(endOfDay)
      },
      {
        "field": {
          "name": "data.scheduled_arrival_time",
          "type": DateTime
        },
        "op": "gte",
        "value": DateService.getUtcISOString(startOfDay)
      },
      {
        "field": {
          "name": "data.scheduled_arrival_time",
          "type": DateTime
        },
        "op": "lte",
        "value": DateService.getUtcISOString(endOfDay)
      },
      // {
      //   "field": {
      //     "name": "data.driver_id",
      //     "type": "string"
      //   },
      //   "op": "in",
      //   "value": driverIds
      // }
    ],
    "operator": "AND"
  };
  return DriverAvailabilityApi.findDriverTrips(filters);
}


export const getSuggestedDrivers = async (
  departureTime: string,
  arrivalTime: string,
  driverId: string,
  isDg: boolean,
) => {
  const filters: QueryFilter[] = [
    {
      "field": {
        "name": "data.start_time",
        "type": DateTime
      },
      "op": "gte",
      "value": DateService.getUtcISOString(departureTime)
    },
    {
      "field": {
        "name": "data.finish_time",
        "type": DateTime
      },
      "op": "lte",
      "value": DateService.getUtcISOString(arrivalTime)
    },
    {
      "field": {
        "name": "data.assigned_trip",
        "type": "bool"
      },
      "op": "eq",
      "value": false
    },
    {
      "field": {
        "name": "data.driver_id",
        "type": "string"
      },
      "op": "ne",
      "value": driverId
    }
  ]
  if (isDg) {
    filters.push({
      "field": {
        "name": "data.dangerous_goods",
        "type": "bool"
      },
      "op": "lte",
      "value": false
    });
  }
  const query: ApiQuery = {
    "filters": filters,
    "operator": "AND"
  };
  return DriverAvailabilityApi.findAvailabileDrivers(query);
}

export const getDriverAvailability = async (
  fromDate: string,
  toDate: string,
) => {
  // filter driverIds
  const filters: QueryFilter[] = [
    {
      "field": {
        "name": "data.weekday",
        "type": Number
      },
      "op": Equals,
      "value": DateService.getWeekday(toDate)
    },
  ]
  
  const query: ApiQuery = {
    "filters": filters,
    "operator": "AND"
  };
  return AvailabilityApi.find(query);
}

export const loadAvailability = (
  fromDate: string,
  toDate: string,
) => async (dispatch: DispatchProps) => {
  dispatch(setIsAvailabilityLoading(true));
  try {
    const response = await getDriverAvailability(fromDate, toDate);
    if (response.status === 200) {
      const data = response.data.data || [];
      dispatch(setIsAvailabilityLoaded(true));
      dispatch(setAvailability(data));
    }
    
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsAvailabilityLoading(false));
  }
};

export const selectAvailability = (state: AppState) => state[SLICE_NAME].availability;
export const selectIsAvailabilityLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsAvailabilityLoading = (state: AppState) => state[SLICE_NAME].isLoading;

export const driverAvailabilityReducer = slice.reducer;