import React, {
  useState,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Spinner, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import {
  selectAreSettingsLoading,
  selectBoard,
  selectDriversByTags,
  selectDriversObject,
  selectGroupsByBoardId,
} from '../../../redux/slices/settings';
import {
  selectAreTripsLoading, selectTrips, // setTripListDriverOptimised
} from '../../../redux/slices/trips/trips';
import {
  LOADING_TEXT,
} from '../constants/boards.constants';
import { AppState } from '../../../redux/models/state.models';
import { Tag } from '../../../redux/models/settings.models';
import { BoardSettings } from '../../../redux/models/board.models';
import BetaBadge from '../../../components/badge/BetaBadge';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import TripPlannerContainer from './TripPlannerContainer';
import {
  createDraft,
  selectCurrentPlan,
  selectIsPlansLoading,
  setCurrentPlan,
  setMergeNewTrips,
} from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { createDraftTripPlan } from '../../../redux/slices/planner/planner.utils';
import { selectAvailability } from '../../../redux/slices/availability/driver.availability';
import Analytics from '../../../utils/analytics';

interface Props {
  boardId: string;
  tags: Tag[];
  boardSettings: BoardSettings;
  dateFrom: string;
  dateTo: string;
}

export default function TripPlanner({ boardId, tags, boardSettings, dateFrom, dateTo }: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const plan = useSelector(selectCurrentPlan);
  const availability = useSelector(selectAvailability);
  const drivers = useSelector((state: AppState) => selectDriversByTags(state, tags));
  const groups = useSelector((state: AppState) => selectGroupsByBoardId(state, boardId));
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const [isCreatingDraft, setIsCreatingDraft] = useState(false);
  const areSettingsLoading = useSelector(selectAreSettingsLoading);
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const arePlansLoading = useSelector(selectIsPlansLoading);
  const tripContainer: EntityContainer<TripBoardModel> = useSelector(selectTrips);
  const driverStore = useSelector(selectDriversObject);
  const [isAvailLoading] = useState(false);
  const isLoading = areSettingsLoading || areTripsLoading || isAvailLoading || arePlansLoading;

  const handleCreateDraft = async () => {
    if (!board) return;
    try {
      setIsCreatingDraft(true);
      const pl = createDraftTripPlan(plan, board, tripContainer, drivers, dateFrom, dateTo);
      const response = await createDraft(pl.data);
      if (response.status === 200) {
        const itemData = response.data.data;
        if (itemData) {
          dispatch(setCurrentPlan(itemData));
        }
      }
    } catch (error) {
      toast("Couldn't Create Draft", { });
    } finally {
      setIsCreatingDraft(false);
      Analytics.createDraft(orgCode);
    }
  }

  useEffect(() => {
    if (!isLoading) {
      dispatch(setMergeNewTrips({ trips: tripContainer, drivers: driverStore }));
    }
  }, [isLoading, tripContainer, driverStore]);

  return (
    <>
      <Row className="mb-3">
        <Stack direction='horizontal' gap={2} className="align-items-center">
          <h3 className="pt-2">Trip Planner</h3>
          <BetaBadge />
        </Stack>
      </Row>
      {isLoading ? <Loader text={LOADING_TEXT} /> : null}
      {!isLoading && !plan && (
        <Button type="button" onClick={handleCreateDraft}>
          {isCreatingDraft ? <Spinner animation="border" variant="primary" size="sm" /> : 'Create Draft'}
        </Button>
      )}
      {!isLoading && plan && (
        <TripPlannerContainer
          availability={availability}
          boardSettings={boardSettings}
          drivers={drivers}
          groups={groups}
          plan={plan}
          tripContainer={tripContainer}
          tags={tags}
        />
      )}
    </>
  );
}
