import { configureStore } from '@reduxjs/toolkit';

import { DRIVER_AVAILABILITY, MENTIONS, NOTIFICATIONS, ROLES, SHIPMENT_LIST } from './slices/slices';

import { tripsReducer } from './slices/trips/trips';
import { shipmentsReducer } from './slices/shipments';
import { settingsReducer } from './slices/settings';
import { sharedReducer } from './slices/shared';
import { trailerChangesReducer } from './slices/trailerChanges';
import { invoicesReducer } from './slices/invoices';
import { mentionsReducer } from './slices/mentions';
import { plannerReducer } from './slices/planner/planner';
import { roleReducer } from './slices/roles/roles';
import { driverAvailabilityReducer } from './slices/availability/driver.availability';
import { shipmentListReducer } from './slices/shipment-list/shipment-list';
import { notificationsReducer } from './slices/notifications/notifications';

export const store = configureStore({
  reducer: {
    [DRIVER_AVAILABILITY]: driverAvailabilityReducer,
    invoices: invoicesReducer,
    [MENTIONS]: mentionsReducer,
    [NOTIFICATIONS]: notificationsReducer,
    planner: plannerReducer,
    [ROLES]: roleReducer,
    shipments: shipmentsReducer,
    settings: settingsReducer,
    shared: sharedReducer,
    [SHIPMENT_LIST]: shipmentListReducer,
    trailerChanges: trailerChangesReducer,
    trips: tripsReducer,
  },
});
