/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import useAppDispatch from '../../../../hooks/useAppDispatch';
import { SelectInput } from '../../../../components/shared/Input';
import CustomerForm from './ShipmentTypes/CustomerForm';
import ProductForm from './ShipmentTypes/ProductForm';
import { updateShipmentDetails } from '../../../../redux/slices/shipments';
import {
  updateShipmentDetails as updateShipmentDetailsPaginated
} from '../../../../redux/slices/shipment-list/shipment-list';
import { SHIPMENT_STATUS_TYPES } from '../../constants/shipments.constants';
import { Shipment, ShipmentTypeFieldData } from '../../../../redux/models/shipment.models';
import { BillingItem, BillingType } from '../../../../redux/models/billing.models';
import ShippingTypeForm from './ShipmentTypes/ShippingTypeForm';
import { EntityContainer } from '../../../../redux/models/core.models';
import { DropdownOptionsModel } from '../../../../components/shared/SelectInput.models';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import { handleShipmentFormData } from '../../utils/shipments.utils';

const SHIPMENT_FORM_ID = 'shipmentDetails';

interface Props {
  shipmentDetails: Shipment;
  fields: ShipmentTypeFieldData;
  billingTypes: BillingType[];
  billingItems: BillingItem[];
  validation: EntityContainer<boolean>;
  shipmentTypeEntityOptions: DropdownOptionsModel[];
  customerSelectOptions: DropdownOptionsModel[];
  addressSelectOptions: DropdownOptionsModel[];
  shippingLineOptions: DropdownOptionsModel[];
  isEditable: any;
  portSelectOptions: DropdownOptionsModel[];
  products: DropdownOptionsModel[];
  uomSelectOptions: DropdownOptionsModel[];
  weightUomSelectOptions: DropdownOptionsModel[];
  // onCreateCustomer: (prop: string, val: string) => void;
}

export default function ShipmentForm({
  shipmentDetails,
  billingTypes,
  billingItems,
  fields,
  customerSelectOptions,
  addressSelectOptions,
  shipmentTypeEntityOptions,
  isEditable,
  portSelectOptions,
  products,
  uomSelectOptions,
  shippingLineOptions,
  weightUomSelectOptions,
  validation,
  // onCreateCustomer,
}: Props) {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const statusTypeOptions = Object.values(SHIPMENT_STATUS_TYPES);

  const handleForm = (prop: string, value: string) => {
    const updates = handleShipmentFormData(
      prop,
      value,
      shipmentDetails,
      billingTypes,
      billingItems,
    );
    if (isPaginationEnabled) {
      dispatch(updateShipmentDetailsPaginated(updates));
    } else {
      dispatch(updateShipmentDetails(updates));
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="mt-2">
            <Col xs={6}>
              <SelectInput
                value={shipmentDetails.data.status || ''}
                labelText="Status"
                name="status"
                page={SHIPMENT_FORM_ID}
                options={statusTypeOptions}
                handleChange={handleForm}
                dataCy="shipment_status"
                disabled={!isEditable}
              />
            </Col>
            <Col xs={6}>
              <SelectInput
                value={shipmentDetails.data.type_id}
                labelText="Shipment Type"
                name="type_id"
                page={SHIPMENT_FORM_ID}
                options={shipmentTypeEntityOptions}
                handleChange={handleForm}
                dataCy="shipment_type_id"
                disabled={!isEditable}
              />
            </Col>
          </Row>
          {isEditable && (
            <CustomerForm
              shipmentDetails={shipmentDetails}
              customerSelectOptions={customerSelectOptions}
              page={SHIPMENT_FORM_ID}
              disabled={!isEditable}
              validation={validation}
              fields={fields}
              handleChange={handleForm}
              // onCreateCustomer={onCreateCustomer}
            />
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>   
          <ProductForm
            shipmentDetails={shipmentDetails}
            uomSelectOptions={uomSelectOptions}
            weightUomSelectOptions={weightUomSelectOptions}
            page={SHIPMENT_FORM_ID}
            handleChange={handleForm}
            products={products}
            disabled={!isEditable}
            validation={validation}
            fields={fields}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ShippingTypeForm
            shipmentDetails={shipmentDetails}
            addressSelectOptions={addressSelectOptions}
            shippingLineOptions={shippingLineOptions}
            portSelectOptions={portSelectOptions}
            disabled={!isEditable}
            validation={validation}
            fields={fields}
            handleChange={handleForm}
          />
        </Card.Body>
      </Card>
    </>
  );
}
