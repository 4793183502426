import moment from 'moment-timezone';

export class DateService {
  static getMomentDate(date) {
    return moment(date);
  }

  static updateDateToToday(isoDateString) {
    // Parse the ISO string with Moment.js
    const originalMoment = moment(isoDateString);
    if (!originalMoment.isValid()) {
        throw new Error('Invalid date string');
    }

    // Get the current date
    const currentMoment = moment();

    // Update the original moment with today's date but keep the time
    originalMoment.year(currentMoment.year());
    originalMoment.month(currentMoment.month());
    originalMoment.date(currentMoment.date());

    // Return the updated ISO string
    return originalMoment.toISOString();
  }

  static convertLocalTimeToUTCISO(localTime) {
    const now = new Date();
    const [hours, minutes] = localTime.split(':').map(Number);
    now.setHours(hours, minutes, 0, 0);
    return now.toISOString();
  }

  static updateTimeStrToDate(isoTimeString, toDate) {
    const originalMoment = moment(isoTimeString);
    if (!originalMoment.isValid()) {
        throw new Error('Invalid date string');
    }

    // Get the current date
    const targetMoment = moment(toDate);

    // Update the original moment with today's date but keep the time
    originalMoment.year(targetMoment.year());
    originalMoment.month(targetMoment.month());
    originalMoment.date(targetMoment.date());

    // Return the updated ISO string
    return originalMoment.toISOString();
  }

  static getUtcISOString(date) {
    return date ? moment.utc(date).toISOString() : moment.utc().toISOString();
  }

  static getWeekday(date) {
    return date ? moment(date).isoWeekday() : moment().isoWeekday();
  }

  static getDay(day) {
    const days = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    };
    return days[day];
  }

  static getISOString(date, keepOffset = false) {
    return date ? moment(date).toISOString(keepOffset) : moment().toISOString(keepOffset);
  }

  static getStartOfTheDayISO(date) {
    return date ? moment(date).startOf('day').toISOString() : moment().startOf('day').toISOString();
  }

  static getEndOfTheDayISO(date) {
    return date ? moment(date).endOf('day').toISOString() : moment().endOf('day').toISOString();
  }

  static getStartOfTheWeekISO(date) {
    return date ? moment(date).startOf('week').toISOString() : moment().startOf('week').toISOString();
  }

  static getEndOfTheWeekISO(date) {
    return date ? moment(date).endOf('week').toISOString() : moment().endOf('week').toISOString();
  }

  static getStartOfTheMonthISO(date) {
    return date ? moment(date).startOf('month').toISOString() : moment().startOf('month').toISOString();
  }

  static getEndOfTheMonthISO(date) {
    return date ? moment(date).endOf('month').toISOString() : moment().endOf('month').toISOString();
  }

  static getUnixTime(date) {
    return date ? moment(date).unix() : moment().unix();
  }

  static isFutureUnix(timestampInSeconds) {
    const now = moment().unix();
    return timestampInSeconds > now;
  }

  static getYYYYMMDDFormat(date) {
    return date ? moment(date).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD');
  }

  static getDoMMMFormat(date) {
    return moment(date).format('Do MMM');
  }

  static getDDMMFormat(date) {
    return moment(date).format('DD/MM');
  }

  static getDDMMYYYFormat(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  static getHHmmFormat(date) {
    return moment(date).format('HH:mm');
  }

  static getHHmmaFormat(date) {
    return moment(date).format('HH:mm a');
  }

  static getYYYYMMDDhhmmFormat(date) {
    return moment(date).format('YYYY-MM-DD HH:mm A');
  }

  static isMidnight(date) {
    const parsedDate = moment(date);
    return parsedDate.hour() === 0 && parsedDate.minute() === 0 && parsedDate.second() === 0;
  }

  static getDoMMMHHmmFormat(date) {
    return moment(date).format('HH:mm Do MMM');
  }

  static isDateValid(date) {
    return moment(date).isValid();
  }

  static getDifferenceInHours(date) {
    return moment.duration(moment().diff(date))?.asHours();
  }

  static getDifferenceInMinutes(aDate, bDate) {
    return moment.duration(moment(aDate).diff(bDate))?.asMinutes();
  }

  static getDifferenceBetweenInHours(a, b) {
    return moment.duration(moment(a).diff(b))?.asHours();
  }

  static subtractDaysFromDateISO(date, days) {
    return date ? moment(date).subtract(days, 'days').toISOString()
      : moment().subtract(days, 'days').toISOString();
  }

  static addDaysToDateISO(date, days) {
    return date ? moment(date).add(days, 'days').toISOString()
      : moment().add(days, 'days').toISOString();
  }

  static subtractWeeksFromDateISO(date, weeks) {
    return date ? moment(date).subtract(weeks, 'weeks').toISOString()
      : moment().subtract(weeks, 'weeks').toISOString();
  }

  static addWeeksToDateISO(date, weeks) {
    return date ? moment(date).add(weeks, 'weeks').toISOString()
      : moment().add(weeks, 'weeks').toISOString();
  }

  static subtractMonthsFromDateISO(date, months) {
    return date ? moment(date).subtract(months, 'months').toISOString()
      : moment().subtract(months, 'months').toISOString();
  }

  static addMonthsToDateISO(date, months) {
    return date ? moment(date).add(months, 'months').toISOString()
      : moment().add(months, 'months').toISOString();
  }

  static subtractHoursFromDateISO(date, hours) {
    return date ? moment(date).subtract(hours, 'hours').toISOString()
      : moment().subtract(hours, 'hours').toISOString();
  }

  static getFromNow(date) {
    return moment(date).fromNow();
  }

  static getDayName(date) {
    return moment(date).format('dddd');
  }

  static addMinsToDateISO(date, minutes, keepOffset = false) {
    return date ? moment(date).add(minutes, 'minutes').toISOString(keepOffset)
      : moment().add(minutes, 'minutes').toISOString(keepOffset);
  }

  static isSame(date1, date2, unit = 'day') {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    return momentDate1.isSame(momentDate2, unit);
  }

  static spansMidnight(startTime, finishTime) {
    // Create moment objects for start and finish times
    const startMoment = moment(startTime, 'HH:mm');
    const finishMoment = moment(finishTime, 'HH:mm');
  
    // If the finish time is earlier than the start time, it spans midnight
    return finishMoment.isBefore(startMoment);
  }

  /// convert millisecond number to hh:mm string
  static msToTime(duration) {
    // Convert milliseconds to seconds
    const seconds = Math.floor(duration / 1000);
    // Convert seconds to minutes
    let minutes = Math.floor(seconds / 60);
    // Convert minutes to hours
    const hours = Math.floor(minutes / 60);
  
    // Adjust minutes to be within 0 - 59 by getting the remainder when divided by 60
    minutes %= 60;
  
    // Format hours and minutes to ensure they are always shown as two digits
    const hrPadding = hours > 9 ? 2 : 1;
    const hoursStr = hours.toString().padStart(hrPadding, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
  
    // Return the formatted time string
    return `${hoursStr}:${minutesStr}`;
  }
  
  static getTimezoneOffset(timezoneKey, date = new Date()) {
    const dec = moment(date); // test daylight savings time "2014-12-01T12:00:00Z"
    const olmate = dec.tz(timezoneKey).format('Z');
    return olmate;
  }

  static toRelativeTime(isoDateString) {
    const time = moment(isoDateString);
    const now = moment();
    const diffMinutes = now.diff(time, 'minutes');
    const isPlural = (diffs) => diffs !== 1;
    if (diffMinutes < 60 && diffMinutes > -60) {
        const plural = isPlural(diffMinutes) ? 's' : '';
        return `${diffMinutes} minute${plural} ago`;
    }
    const diffHours = now.diff(time, 'hours');
    if (diffHours < 24 && diffHours > -24) {
      const plural = isPlural(diffHours) ? 's' : '';
      return `${diffHours} hour${plural} ago`;
    }
    const diffDays = now.diff(time, 'days');
    if (diffDays < 8 && diffDays > -8) {
      const plural = isPlural(diffDays) ? 's' : '';
      return `${diffDays} day${plural} ago`;
    }
    const diffWeeks = now.diff(time, 'weeks');
    if (diffWeeks < 5 && diffWeeks > -5) {
      const plural = isPlural(diffWeeks) ? 's' : '';
      return `${diffWeeks} week${plural} ago`;
    }
    const diffMonths = now.diff(time, 'months');
    if (diffMonths < 12 && diffMonths > -12) {
      const plural = isPlural(diffMonths) ? 's' : '';
      return `${diffMonths} month${plural} ago`;
    }
    const diffYears = now.diff(time, 'years');
    const plural = isPlural(diffYears) ? 's' : '';
    return `${diffYears} year${plural} ago`;
  }
}
