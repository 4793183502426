import { ACTION_DROPDOWN_OPTIONS } from '../../../pages/trips/Moves/constants/moves.constants';
import { getEmptyStateOptions, getLocationOptions, getValue } from '../../../pages/trips/Moves/utils/moves.utils';
import { createDropdownOptions, getSelectedOptions } from '../../../utils/core.utils';
import { SelectInput, SelectInputWithSearchLocalData } from '../../shared/Input';

const renderShipments = (shipmentsArr, shipments) => shipmentsArr.map((id) => (
  <span
    key={id}
    className="trailerChanges__move-shipment"
  >
    {getValue(id, shipments, 'shipment_no')}
  </span>
));

export default function TrailerChangeTableRow({
  move, prop, shipments, fleet, onTrailerChangeInput, fleetLocations,
}) {
  const fleetLocationDropdownOptions = getLocationOptions(fleetLocations, fleet);
  const fleetDropdownOptions = createDropdownOptions(Object.values(fleet));

  if (prop === 'shipments') {
    return renderShipments(move.shipments, shipments);
  }

  if (prop === 'from') {
    return getValue(move.from, fleet);
  }

  if (prop === 'to') {
    return (
      <SelectInputWithSearchLocalData
        name="trailer_id"
        dataCy="trailerChanges_move_to_input"
        page="trailerChanges"
        value={move.to}
        options={getSelectedOptions(move.to, fleetDropdownOptions)}
        emptyStateOptions={getEmptyStateOptions(move, fleetLocationDropdownOptions)}
        shouldHaveMargin={false}
        settingName="fleet"
        validDriverOptions={fleetDropdownOptions}
        handleChange={onTrailerChangeInput(move.position)}
      />
    );
  }

  if (prop === 'action') {
    return (
      <SelectInput
        name="action"
        page="trailerChanges"
        value={move.action}
        options={ACTION_DROPDOWN_OPTIONS}
        shouldHaveMargin={false}
        dataCy="trailerChanges_action"
        handleChange={onTrailerChangeInput(move.position)}
      />
    );
  }
  return move[prop];
}
