import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useSidebar from '../../hooks/useSidebar';
import NavbarUserDropdown from './NavbarUserDropdown';
import { pusher } from '../../services/pusher';
import useAuth from '../../hooks/useAuth';
import { AsyncState } from '../../services/notifications/Notification.models';
import useAppDispatch from '../../hooks/useAppDispatch';
import { RequestIdState, setTripDispatchNotification } from '../../redux/slices/notifications/notifications';
import DispatchNotificationContainer from '../notifications/DispatchNotificationContainer';
import NavbarDispatchNotifications from './NavbarDispatchNotifications';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const getRoute = (notification) => {
  const entityId = notification.entity_id || '';
  const type = notification.entity_type || '';
  if (type === 'trip') return `trips/${entityId}`;
  if (type === 'board') return `boards/${entityId}`;
  if (type === 'shipment') return `shipments/${entityId}`;
  return `${type}/${entityId}`;
};

const getToastType = (state) => {
  const { DEFAULT, SUCCESS, ERROR, INFO } = toast.TYPE;
  if (state === AsyncState.QUEUED) return INFO;
  if (state === AsyncState.SUCCESS) return SUCCESS;
  if (state === AsyncState.ERROR) return ERROR;
  return DEFAULT;
}

function NavbarComponent() {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const { user } = useAuth();
  const { isOpen, setIsOpen } = useSidebar();
  const features = useFeatureFlags();
  const groupDispatch = features.async_dispatch?.is_enabled || false;
  const plannerDispatch = features.async_dispatch?.is_enabled || false;
  const isNotificationsEnabled = groupDispatch || plannerDispatch;
  const userId = user.id || '';
  useEffect(() => {
    const subscribe = (org, identity) => {
      const channelIdentity = identity.replace(/\|/g, '-');
      const channelId = `${org}-${channelIdentity}`;
      console.log('Subscribe to notification channel: ', channelId);
      const boardChannel = pusher.subscribe(channelId);
      boardChannel.bind('update', (notification) => {
        const requestId = notification.request_id || '';
        const entity = notification.entity_type || '';
        if (entity === 'trip') {
          dispatch(setTripDispatchNotification({ notification, orgCode: org }));
        } else {
          if (RequestIdState.shown[requestId] === requestId) return;
          RequestIdState.shown[requestId] = requestId;
          const title = notification.title || '';
          const state = notification.state || '';
          const route = getRoute(notification);
          toast(<div>
            {`${title}. `}
            <Link to={`/${org}/${route}`}>
              <span>{`Open ${entity}`}</span>
            </Link>
          </div>, { type: getToastType(state) });
        }
      });
    }
    if (orgCode && userId) subscribe(orgCode, userId);
  }, [orgCode, userId, dispatch]);

  useEffect(() => {
    return () => {
      pusher.unsubscribe(orgCode || '');
      RequestIdState.shown = {};
    };
  }, [orgCode]);
  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        id="nav-sidebar_toggle__btn"
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Navbar.Collapse>
        <Nav className="navbar-align">
          {isNotificationsEnabled && <NavbarDispatchNotifications />}
          <NavbarUserDropdown />
        </Nav>
      </Navbar.Collapse>
      <DispatchNotificationContainer />
    </Navbar>
  );
}

export default NavbarComponent;
