/* eslint-disable @typescript-eslint/no-explicit-any */
import { Move, Trip } from '../../../redux/models/trip.models';

interface NoteChild {
  text: string;
}

// { children: [{ text: "" }], type: "paragraph" }
export interface DispatchNote {
  children: NoteChild[];
  type: string;
}

export const isChildNoteEmpty = (note: DispatchNote) => {
  const childNotes = note.children || [];
  const childLen = childNotes.length;
  if (childLen === 0) return true;
  const emptyNotes = childNotes.filter((child) => child.text === '');
  return emptyNotes.length === childLen;
};

export const isDispatchNotesInvalid = (notes: DispatchNote[]) => {
  const noteLength = notes.length;
  if (noteLength === 0) return true;
  if (noteLength === 1) {
    const firstNote = notes[0];
    return isChildNoteEmpty(firstNote);
  }
  /**
   * User has tapped enter a bunch of times for the following case. We consider this 'not empty' and
   * won't be overwritten with default notes. 
   * 
   * Note: This has potential for user error if they're trying to clear the text field 
   * in order to use default notes but have accidentally left some trailing line break that they can't see
   */
  // const emptyNotes = notes.filter((note: any) => isNoteEmpty(note));
  // return emptyNotes.length === noteLength;
  return false;
}

export const isMoveMissingNotes = (move: Move) => {
  const moveNotes = move.data.dispatch_notes;
  if (moveNotes === undefined || moveNotes === null) {
    return true;
  }
  const notes: any = moveNotes || [];
  return isDispatchNotesInvalid(notes);
}

export const isTripMoveMissingNotes = (trip: Trip) => {
  const moves = trip.data.moves || [];
  const missingNote = moves.some((move) => isMoveMissingNotes(move));
  return missingNote;
}

export const addDefaultDispatch = (notesTemplates: Array<any>, trip: Trip) => {
  if (notesTemplates.length === 0) {
    return trip;
  }
  const defaultNotes = notesTemplates[0].data.notes || [];
  if (defaultNotes.length === 0) {
    return trip;
  }
  return {
    ...trip,
    data: {
      ...trip.data,
      moves: trip.data.moves.map((move) => {
        const isNoteEmpty = isMoveMissingNotes(move);
        if (isNoteEmpty) {
          return {
            ...move,
            data: {
              ...move.data,
              dispatch_notes: defaultNotes,
            },
          };
        }
        return move;
      }),
    },
  };
};
