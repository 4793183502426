import React from 'react';
import { useSelector } from 'react-redux';
import { DropTargetMonitor } from 'react-dnd';
import { Button, Card, Form, Row, Stack } from 'react-bootstrap';
import { DropdownOptionsModel } from '../../../components/shared/SelectInput.models';
import { BoardSettings } from '../../../redux/models/board.models';
import DriverSection from './Drivers/DriverSection';
import { selectTrips } from '../../../redux/slices/trips/trips';

import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { SearchDateRange } from '../../../components/modals/SearchFilter/searchFilter.models';
import { findDriverAvailability } from '../../../redux/slices/planner/planner.utils';
import { DriverFilters, TripPlan } from '../../../redux/slices/planner/planner.models';
import { Driver, Tag } from '../../../redux/models/settings.models';
import { selectDriverFilters, setDriverFilters } from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import DropWrapper from './components/DropWrapper';
import { ItemModel } from './components/ItemModel';

const tripsFilter = (driverId: string, plan: TripPlan) => {
  const plans = plan.data.plans || {};
  return plans[driverId] && plans[driverId].length > 0;
};

const avFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

const tagFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

interface Props {
  dateFilters: SearchDateRange;
  drivers: Driver[];
  data: Driver[];
  driverOptions: DropdownOptionsModel[];
  boardSettings: BoardSettings;
  availability: DriverAvailability[];
  plan: TripPlan;
  tags: Tag[];
  handleDrop: (item: ItemModel, monitor: DropTargetMonitor<unknown, unknown>, driverId: string) => void;
}

export default function TripPlannerDriverCard({
  availability, data, drivers, driverOptions, dateFilters, plan, boardSettings, tags, handleDrop
}: Props) {
  const dispatch = useAppDispatch();
  const tripStore = useSelector(selectTrips);
  const filters = useSelector(selectDriverFilters);
  const {
    available,
    numTrips,
    filtersOpen,
    allDriversOpen,
    tagFilters,
    outsideCartage,
  } = filters;

  const collapseText = allDriversOpen ? 'Collapse All' : 'Open All';
  const isTagFilters = Object.values(tagFilters).find((filter) => filter);
  const isFilter = numTrips || available || isTagFilters;
  
  const unfiltered = isFilter ? drivers.filter((option) => {
    const driverId = option.entity_id;
    const driverFilters: boolean[] = [];
    if (numTrips) {
      driverFilters.push(tripsFilter(driverId, plan));
    }
    if (available) {
      driverFilters.push(avFilter(driverId, dateFilters.dateFrom, availability));
    }
    if (isTagFilters) {
      driverFilters.push(tagFilter(driverId, dateFilters.dateFrom, availability));
    }
    // if (outsideCartage) {
    //   driverFilters.push(option.data.outside_cartage);
    // }
    return driverFilters.filter((flt) => flt === false).length === driverFilters.length;
  }) : [];
  const remainingInternalDrivers = unfiltered.filter((dr) => !dr.data.outside_cartage);
  const remainingOutsideCartage = unfiltered.filter((dr) => dr.data.outside_cartage);

  const handleSelectTag = (tagId: string, checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      tagFilters: {
        ...tagFilters,
        [tagId]: checked,
      },
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleOpenFilters = () => {
    const filterData: DriverFilters = {
      ...filters,
      filtersOpen: !filtersOpen,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleOpenDrivers = () => {
    const filterData: DriverFilters = {
      ...filters,
      allDriversOpen: !allDriversOpen,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleNumTrips = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      numTrips: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleAvail = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      available: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleOutsideCartage = (checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      outsideCartage: checked,
    };
    dispatch(setDriverFilters(filterData));
  };
  const handleToggleDriver = (driverId: string, checked: boolean) => {
    const filterData: DriverFilters = {
      ...filters,
      selected: {
        ...filters.selected,
        [driverId]: checked,
      },
    };
    dispatch(setDriverFilters(filterData));
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Stack className="pb-2" direction="horizontal" gap={4}>
            <h4>{`Drivers (${data.length})`}</h4>
            <Button onClick={handleOpenFilters} variant="link"><h6>Filters</h6></Button>
            <Button onClick={handleOpenDrivers} variant="link"><h6>{collapseText}</h6></Button>
          </Stack>
          {filtersOpen && (
            <Stack className="pb-2">
              <Stack className="pb-2" direction="horizontal" gap={4}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Drivers With Trips"
                  className="ml-2"
                  data-cy="board_planner_table_trips_toggle"
                  checked={numTrips}
                  onChange={(e) => handleNumTrips(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Available"
                  className="ml-2"
                  data-cy="board_planner_table_available_toggle"
                  checked={available}
                  onChange={(e) => handleAvail(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Outside Cartage"
                  className="ml-2"
                  data-cy="board_planner_table_outside_cartage_toggle"
                  checked={outsideCartage}
                  onChange={(e) => handleOutsideCartage(e.target.checked)}
                />
              </Stack>
              {tags.length > 0 && (
                <>
                  <h6>Tags</h6>
                  <Stack direction="horizontal" className="py-2">
                    {tags.map((tag)=> (
                      <Form.Check // prettier-ignore
                        key={tag.entity_id}
                        type="switch"
                        id={tag.entity_id}
                        label={tag.data.name}
                        className="ml-2"
                        data-cy={`board_planner_table_tag_toggle__${tag.data.name}`}
                        checked={tagFilters[tag.entity_id] || false}
                        onChange={(e) => handleSelectTag(tag.entity_id, e.target.checked)}
                      />
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Card.Body>
        <Card.Body style={{ maxHeight: '800px', overflowY: 'scroll' }}>
          <Stack gap={3} >
            {data.map((driver) => {
              const driverAv = availability.filter((av) => av.data.driver?.entity_id === driver.entity_id);
              const planData = plan.data.plans || {};
              const drafts = planData[driver.entity_id] || [];
              const draftTrips = drafts.flatMap((draftTripId) => tripStore[draftTripId]);
              const yard = boardSettings.addresses[driver.data.home_yard_id || '']?.data.samsara_name || '';
              return (
                <DropWrapper
                  driverId={driver.entity_id}
                  key={driver.entity_id}
                  onDrop={handleDrop}
                >
                  <DriverSection
                    plan={plan}
                    availability={driverAv}
                    driver={driver}
                    drivers={driverOptions}
                    shouldExpandAllItems={allDriversOpen}
                    trips={draftTrips}
                    yard={yard}
                  />
                </DropWrapper>
              )
            })}
          </Stack>
        </Card.Body>
      </Card>
      {isFilter && unfiltered.length > 0 && (
        <div>
          <div className="d-flex flex-wrap">
            {remainingInternalDrivers.map((dr) => (
              <Stack
                key={dr.entity_id}
                direction="horizontal"
                className="align-items-center bg-white m-2 p-2 rounded"
              >
                <h5 className="my-1 mx-2">{dr.data.samsara_name || ''}</h5>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="board_planner_table_select_company_driver_toggle"
                  label=""
                  className="ml-2"
                  data-cy="board_planner_table_select_company_driver_toggle"
                  checked={filters.selected[dr.entity_id] ?? false}
                  onChange={(e) => handleToggleDriver(dr.entity_id, e.target.checked)}
                />
              </Stack>
            ))}
          </div>
          {remainingOutsideCartage.length > 0 && !outsideCartage && (
            <Row className="mt-5">
              <h4>Outside Cartage</h4>
              <div className="d-flex flex-wrap">
                {remainingOutsideCartage.map((dr) => (
                  <Stack
                    key={dr.entity_id}
                    direction="horizontal"
                    className="align-items-center bg-white m-2 p-2 rounded"
                  >
                    <h5 className="my-1 mx-2">{dr.data.samsara_name || ''}</h5>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="board_planner_table_select_outside_cartage_toggle"
                      label=""
                      className="ml-2"
                      data-cy="board_planner_table_select_outside_cartage_toggle"
                      checked={filters.selected[dr.entity_id] ?? false}
                      onChange={(e) => handleToggleDriver(dr.entity_id, e.target.checked)}
                    />
                  </Stack>
                ))}
              </div>
            </Row>
          )}
        </div>
      )}
    </>
  );
}
