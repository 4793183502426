/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { getValue } from '../../../pages/trips/Moves/utils/moves.utils';
import { selectUpdatedTrailers } from '../../../redux/slices/trailerChanges';

export default function TrailerChangeMsg({
  buttonName, fleet,
}: any) {
  const TRAILER_UPDATE_MESSAGE = `Click ${buttonName} to update:`;

  const trailers = useSelector(selectUpdatedTrailers);

  return (
    <div>
      {TRAILER_UPDATE_MESSAGE}
      <ul>
        {Object.entries(trailers).map((trailer) => {
          const initialTrailer = getValue(trailer[0], fleet);
          const updatedTrailer = getValue(trailer[1], fleet);

          return (
            <li key={initialTrailer}>
              <strong>
                {`${initialTrailer} to ${updatedTrailer}`}
              </strong>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
