import { Form, Table } from 'react-bootstrap';
import Loader from '../../../components/Loader';

import { BILLING_SHIPMENT_TABLE_HEADERS } from './constants/shipmentTable.constants';
import ShipmentsTableRow from './ShipmentsTableRow';
import { TableHeader } from './models/models';
import { ShipmentListView } from '../../../redux/models/shipment.models';
import Permission from '../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';

const WritePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

interface Props {
  headers: TableHeader[];
  areTripsLoading: boolean;
  noShipmentsMessage: string;
  formattedShipments: ShipmentListView[];
  openShipmentPage: (id: string) => void;
  selectShipment: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  selectedShipments: string[];
}

export default function ShipmentsTableComponent({
  headers = BILLING_SHIPMENT_TABLE_HEADERS,
  areTripsLoading,
  noShipmentsMessage = '',
  formattedShipments,
  openShipmentPage,
  selectShipment,
  selectedShipments,
}: Props) {
  const renderNoShipmentsMessage = () => (areTripsLoading ? <Loader text='' />
    : <div>{noShipmentsMessage}</div>);

  return (
    formattedShipments.length ? (
      <Table
        striped
        bordered
        hover
        role="grid"
        className="shipments-table"
        data-cy="shipments-table"
      >
        <thead>
          <tr>
            <Permission resources={WritePermissions}>
              <th className="check-col">{' '}</th>
            </Permission>
            {headers.slice(0).map((header) => (
              <th key={header.key}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formattedShipments.map((shipment, index) => (
            <tr
              key={shipment.id}
              id={`shipments-shipmentTable__tableRow-${index}`}
              className="shipments-table-row"
              data-cy="shipments-table-row"
              onClick={() => openShipmentPage(shipment.id)}
            >
                <td
                  role="gridcell"
                  className="invoice-checkbox-col"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Permission resources={WritePermissions}>
                    <Form.Group data-cy={`multi_invoices_checkbox_${index}`}>
                      <Form.Check
                        checked={selectedShipments?.includes(shipment.id)}
                        className="invoices-checkbox"
                        type="checkbox"
                        onChange={(e) => selectShipment(e, shipment.id)}
                      />
                    </Form.Group>
                  </Permission>
                </td>
              {headers.slice(0).map((header) => (
                <td key={`${shipment.id}-${header.key}`}>
                  <ShipmentsTableRow shipment={shipment} prop={header.key} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : renderNoShipmentsMessage()
  );
}
