import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Spinner, Stack } from 'react-bootstrap';

import { ControlInput, InputSwitch } from '../../../components/shared/Input';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import {
  getSettingByName,
  selectAddressesObject,
  selectCostsObject
} from '../../../redux/slices/settings';

import { Api } from '../../../services/services';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import { ApiQuery, DEFAULT_API_QUERY, getDataSorting } from '../../../redux/models/network.models';
import { getRelQuery } from '../../../redux/slices/network.utils';
import BreadCrumbs, { BreadCrumbRoute } from '../../../components/shared/breadcrumbs/BreadCrumbs';
import { Driver } from '../../../redux/models/settings.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import AddressInput from '../../../components/entities/address/AddressInput';
import DriverDetailsAvailability from './components/DriverDetailsAvailability';
import { EntityContainer } from '../../../redux/models/core.models';
import CostInput from '../../../components/entities/cost/CostInput';
import { errorToast } from '../../../components/notifications/app-toast';

const PAGE = 'driver_details_editor';
const getRoutes = (orgCode: string | undefined, customer: Driver | null): BreadCrumbRoute[] => {
  const routes = [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: 'Drivers',
      route: `/${orgCode}/settings/drivers`,
    },
  ];
  if (customer) {
    routes.push({
      name: customer.data.samsara_name ?? 'No name',
      route: `/${orgCode}/settings/drivers/${customer.entity_id}`,
    });
  }
  return routes;
};

export default function DriverDetails() {
  const dispatch = useAppDispatch();
  const { orgCode, entityId } = useParams();
  const navigate = useNavigate();
  const addresses = useSelector(selectAddressesObject);
  const costs = useSelector(selectCostsObject);
  const [isLoading, setIsLoading] = useState(false);
  const [driver, setDriver] = useState<Driver | null>(null);
  const [availability, setAvailability] = useState<EntityContainer<DriverAvailability>>({});
  const [name, setName] = useState(driver?.data.samsara_name || '');
  const [email, setEmail] = useState(driver?.data.email || '');
  const [homeYardId, setHomeYardId] = useState(driver?.data.home_yard_id || '');
  const [costId, setCostId] = useState(driver?.data.cost_id || '');
  const [phone, setPhone] = useState(driver?.data.samsara_phone || '');
  const [airCargo, setAirCargo] = useState(driver?.data.air_cargo || false);
  const [bTrain, setBTrain] = useState(driver?.data.b_train || false);
  const [ctPat, setCtPat] = useState(driver?.data.ct_pat || false);
  const [dG, setDg] = useState(driver?.data.dangerous_goods || false);
  const [lightDuties, setLightDuties] = useState(driver?.data.light_duties || false);
  const [outsideCartage, setOutsideCartage] = useState(driver?.data.outside_cartage || false);
  const [tipper, setTipper] = useState(driver?.data.tipper_truck || false);

  const getHomeYard = () => {
    if (homeYardId === '') return null;
    const yard = addresses[homeYardId];
    return yard;
  };
  const getDriverCost = () => {
    if (costId === '') return null;
    const cost = costs[costId];
    console.log(cost);
    return cost;
  };
  const handleStrUpdate = (prop: string, value: string) => {
    if (prop === 'name') setName(value);
    if (prop === 'phone') setPhone(value);
    if (prop === 'email') setEmail(value);
    if (prop === 'home_yard_id') setHomeYardId(value);
    if (prop === 'cost_id') setCostId(value);
  };

  const handleBoolUpdate = (prop: string, value: boolean) => {
    if (prop === 'air_cargo') setAirCargo(value);
    if (prop === 'b_train') setBTrain(value);
    if (prop === 'ct_pat') setCtPat(value);
    if (prop === 'dangerous_goods') setDg(value);
    if (prop === 'light_duties') setLightDuties(value);
    if (prop === 'outside_cartage') setOutsideCartage(value);
    if (prop === 'tipper_truck') setTipper(value);
  };

  const handleClose = () => navigate(`/${orgCode}/settings/drivers`);
  const handleSave = async () => {
    if (!driver) return;
    const updates: Driver = {
      ...driver,
      data: {
        ...driver.data,
        samsara_name: name,
        samsara_phone: phone,
        email,
        home_yard_id: homeYardId,
        home_yard: getHomeYard(),
        air_cargo: airCargo,
        cost: getDriverCost(),
        b_train: bTrain,
        ct_pat: ctPat,
        dangerous_goods: dG,
        light_duties: lightDuties,
        outside_cartage: outsideCartage,
        tipper_truck: tipper,
      },
    };
    try {
      const response = await Api.Drivers.update(updates);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.DRIVERS, false));
        handleClose();
      }
    } catch (error) {
      errorToast(`Couldn't save ${name || 'shipment template'}`);
    }
  };

  const handleDelete = async () => {
    if (!driver) return;
    try {
      await Api.Drivers.delete(driver);
      handleClose();
    } catch (error) {
      errorToast("Couldn't delete driver");
    }
  };

  useEffect(() => {
    const handleLoad = async (id: string) => {
      setIsLoading(true);
      const query: ApiQuery = {
        ...DEFAULT_API_QUERY,
        filters: [
          getRelQuery('driver_id', id),
        ],
        sorting: getDataSorting('weekday'),
      }
      const contactResponse = await Api.Drivers.getById(id);
      const response = await Api.Availability.find(query);
      if (contactResponse.status === 200) {
        const data: Driver | null | undefined = contactResponse.data.data || {};
        if (data) {
          setDriver(data);
          setName(data.data.samsara_name || '');
          setPhone(data.data.samsara_phone || '');
          setEmail(data.data.email || '');
          setHomeYardId(data.data.home_yard?.entity_id || '');
          setCostId(data.data.cost?.entity_id || '');
          setAirCargo(data.data.air_cargo || false);
          setBTrain(data.data.b_train || false);
          setCtPat(data.data.ct_pat || false);
          setDg(data.data.dangerous_goods || false);
          setLightDuties(data.data.light_duties || false);
          setOutsideCartage(data.data.outside_cartage || false);
          setTipper(data.data.tipper_truck || false);
        }
      }
      if (response.status === 200) {
        const data: DriverAvailability[] | null | undefined = response.data.data.items || [];
        if (data) {
          const container: EntityContainer<DriverAvailability> = {};
          const av = data.reduce((store, item) => {
            const weekday = item.data.weekday || '';
            if (weekday === '') return store;
            return {
              ...store,
              [weekday]: item,
            };
          }, container);
          setAvailability(av);
        }
      }
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);

  if (isLoading) return <Spinner animation="border" variant="light" />;

  return (
    <>
      <BreadCrumbs routes={getRoutes(orgCode, driver)} />
      <Card>
        <Card.Header as="h4">Driver Details</Card.Header>
        <Card.Body>
          {isLoading && <Spinner animation="border" variant="primary" />}
          {!isLoading && (
            <Stack>
              <ControlInput
                dataCy={`${PAGE}_name_input`}
                name="name"
                type="text"
                page={PAGE}
                value={name}
                labelText="Name"
                handleChange={handleStrUpdate}
              />
              <ControlInput
                dataCy={`${PAGE}_phone_input`}
                name="phone"
                type="tel"
                page={PAGE}
                value={phone}
                labelText="Phone"
                handleChange={handleStrUpdate}
              />
              <ControlInput
                dataCy={`${PAGE}_email_input`}
                name="email"
                type="tel"
                page={PAGE}
                value={email}
                labelText="Email"
                handleChange={handleStrUpdate}
              />
              <AddressInput
                value={homeYardId}
                label="Home Yard"
                page={PAGE}
                prop="home_yard_id"
                disabled={false}
                allowAdd={false}
                onChange={handleStrUpdate}
                onNewEntity={() => {}}
              />
              <CostInput
                value={costId}
                label="Rate"
                page={PAGE}
                prop="cost_id"
                disabled={false}
                allowAdd={false}
                onChange={handleStrUpdate}
                onNewEntity={() => {}}
              />
              {driver && (
                <DriverDetailsAvailability
                  availability={availability}
                  driver={driver}
                  setAvailability={setAvailability}
                />
              )}
              {/* <h4>Certificates</h4> */}
              <Row>
                <h6>Air Cargo</h6>
                <InputSwitch
                  labelText=""
                  name="air_cargo"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={airCargo}
                  dataCy="air_cargo"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>B Train</h6>
                <InputSwitch
                  labelText=""
                  name="b_train"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={bTrain}
                  dataCy="b_train"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>CT Pat</h6>
                <InputSwitch
                  labelText=""
                  name="ct_pat"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={ctPat}
                  dataCy="ct_pat"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>Light Duties</h6>
                <InputSwitch
                  labelText=""
                  name="light_duties"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={lightDuties}
                  dataCy="light_duties"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>Outside Cartage</h6>
                <InputSwitch
                  labelText=""
                  name="outside_cartage"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={outsideCartage}
                  dataCy="outside_cartage"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>Dangerous Goods</h6>
                <InputSwitch
                  labelText=""
                  name="dangerous_goods"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={dG}
                  dataCy="dangerous_goods"
                  className=""
                  disabled={false}
                />
              </Row>
              <Row>
                <h6>Tipper Truck</h6>
                <InputSwitch
                  labelText=""
                  name="tipper_truck"
                  page={PAGE}
                  handleChange={handleBoolUpdate}
                  checked={tipper}
                  dataCy="tipper_truck"
                  className=""
                  disabled={false}
                />
              </Row>
            </Stack>
          )}
        </Card.Body>
        {!isLoading && (
          <Card.Footer>
            <Button variant="outline-danger" onClick={handleDelete}>Delete</Button>
            <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
              <Button variant="secondary" onClick={handleClose}>Cancel</Button>
              <AsyncButton
                title="Save"
                variant="primary"
                spinner="light"
                dataCy={`${PAGE}_save_button`}
                handleClick={handleSave}
              />
            </Stack>
          </Card.Footer>
        )}
      </Card>
    </>
  );
}
