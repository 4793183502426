/* eslint-disable import/no-cycle */
import Analytics from "../../../utils/analytics";
import { Board } from "../../models/board.models";
import { DataModel, EntityContainer } from "../../models/core.models";
import {
  // createDraft,
  // getPlannerFromStore
} from "./planner.utils";

export interface Location {
  name: string;
  lat: number;
  lng: number;
}

export interface Appointment {
  time: Date;
  location: Location;
}

export interface Trip {
  id: string;
  start: Appointment;
  end: Appointment;
  containsDangerousGoods: boolean;
  containsAirCargo: boolean;
}

export interface Driver {
  id: string;
  name: string;
  homeLocation: Location;
  shiftStartTime: Date;
  shiftEndTime: Date;
  dangerousGoodsCertified: boolean;
  airCargoCertified: boolean;
  outsideCartage: boolean;
  assignedTrips: Trip[];
}

export interface DraftTrip {
  driver_id: string;
  trip_id: string;
}

export interface TripPlanData {
  name: string;
  notes?: string;
  start_date: string;
  end_date: string;
  board: Board,
  plans: EntityContainer<string[]>;
  unassigned_trip_ids: string[];
}

export type TripPlan = DataModel<TripPlanData>;

export interface DriverFilters {
  available: boolean;
  numTrips: boolean;
  filtersOpen: boolean;
  allDriversOpen: boolean;
  outsideCartage: boolean;
  selected: EntityContainer<boolean>;
  tagFilters: EntityContainer<boolean>;
}

export interface TripFilters {
  allGroupsOpen: boolean;
  filterAssigned: boolean;
  groupBy: string;
}

export interface PlannerState {
  isLoaded: boolean;
  isLoading: boolean;
  selectedId: string;
  driverFilters: DriverFilters;
  tripFilters: TripFilters;
  plans: EntityContainer<TripPlan>;
};

// const drafts = [
//   createDraft(1),
//   createDraft(2),
// ];

export const initialDriverFilterState: DriverFilters = {
  available: true,
  numTrips: true,
  filtersOpen: true,
  allDriversOpen: true,
  outsideCartage: false,
  selected: {},
  tagFilters: {},
};

export const initialTripFilterState: TripFilters = {
  allGroupsOpen: true,
  filterAssigned: false,
  groupBy: '',
};

const initialState: PlannerState = {
  isLoaded: false,
  isLoading: false,
  driverFilters: initialDriverFilterState,
  tripFilters: initialTripFilterState,
  selectedId: '', // drafts[0].name,
  plans: {
    // [drafts[0].name]: getPlannerFromStore() || drafts[0],
    // [drafts[1].name]: drafts[1],
  },
};

export const getInitialState = (): PlannerState => ({
  ...initialState
});

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} invoices. Please contact support if the problem persists`;

export const getError = (
  e: unknown,
  details = "Couldn't get invoices",
  verb = 'get'
): React.ReactText => {
  let em;
  if (e instanceof Error) {
    em = e.message;
    Analytics.capture(em);
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return errorMessage;
};
