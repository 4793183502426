import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';



import Loader from '../../../components/Loader';

import { selectAreTripsLoading, selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import { TripTableColumns } from '../../../redux/models/board.models';
import { DateService } from '../../../utils/dateService';
import { SHIPMENT_TABLE_HEADERS } from '../TripTable/constants/tripTable.constants';
import TripsTableRow from '../TripTable/TripsTableRow';
import { TripBoardShipment } from '../../../redux/models/shipment.models';
import { renderShipmentStatus } from '../../shipments/ShipmentsTable/ShipmentsTableRow';

const NO_SHIPMENTS_MESSAGE = 'No shipments. Create a shipment to get started.';

interface Props {
  shipments: TripBoardShipment[];
  title: string;
  headers: TripTableColumns[];
}

export default function ShipmentsTable({
  shipments,
  title,
  headers = SHIPMENT_TABLE_HEADERS,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const areTripsLoading = useSelector(selectAreTripsLoading);
  const { dateFrom, dateTo } = useSelector(selectTripsFilterParams);
  const isSameDay = DateService.isSame(dateFrom, dateTo);
  const openTripPage = (id: string) => navigate(`/${orgCode}/shipments/${id}`);
  const renderNoTripsMessage = () => (areTripsLoading ? <Loader /> : <div>{NO_SHIPMENTS_MESSAGE}</div>);
  return (
    shipments.length ? (
      <Table
        striped
        bordered
        hover
        className="trips-table"
        data-cy={`${title}_trips-table`}
      >
        <thead>
          <tr>
            {headers.map((header) => <th key={header.key}>{header.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {shipments.map((trip, index) => (
            <tr
              key={trip.id}
              id={`boards-shipmentsTable__tableRow-${index}-${trip.id}`}
              className="trips-table-row"
              data-cy={`${title}shipments-table-row`}
              onClick={() => openTripPage(trip.id)}
            >
              {headers.map((header) => (
                <td
                  key={`${trip.id}-${header.key}`}
                  data-cy={`boards-shipmentsTable__tableRow-${index}-${header.key}`}
                  id={`boards-shipmentsTable__tableRow-${index}-${header.key}`}
                >
                  <TripsTableRow trip={trip} prop={header.key} boardDate={dateFrom} isDateRange={!isSameDay} />
                  {header.key === 'status' && renderShipmentStatus(trip.shipment_status)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : renderNoTripsMessage()
  );
}
