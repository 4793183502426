import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

import ShipmentDetails from './ShipmentDetails';
import Loader from '../../../components/Loader';

import useAppDispatch from '../../../hooks/useAppDispatch';

import {
  getShipmentDetails,
  selectAreShipmentDetailsLoading,
  selectShipmentDetails,
  resetShipments,
} from '../../../redux/slices/shipments';
import {
  getShipmentDetails as getShipmentDetailsPaginated,
  selectShipmentDetails as selectShipmentDetailsPaginated,
  selectAreShipmentDetailsLoading as selectAreShipmentDetailsLoadingPaginated,
} from '../../../redux/slices/shipment-list/shipment-list';
import { resetInvoices } from '../../../redux/slices/invoices';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

export default function Shipment() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const features = useFeatureFlags();
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;

  useEffect(() => {
    if (id) {
      if (isPaginationEnabled) {
        dispatch(getShipmentDetailsPaginated(id));
      } else {
        dispatch(getShipmentDetails(id));
      }
    }
  }, [dispatch, id, isPaginationEnabled]);

  useEffect(() => () => {
    if (isPaginationEnabled) {
      dispatch(resetShipments());
    }
    dispatch(resetInvoices());
  }, [dispatch, isPaginationEnabled]);

  const shipmentDetails = useSelector(selectShipmentDetails);
  const shipmentDetailsPaginated = useSelector(selectShipmentDetailsPaginated);
  const areShipmentDetailsLoading = useSelector(selectAreShipmentDetailsLoading);
  const areShipmentDetailsLoadingPaginated = useSelector(selectAreShipmentDetailsLoadingPaginated);
  const shipmentData = isPaginationEnabled ? shipmentDetailsPaginated : shipmentDetails;
  const isLoading = isPaginationEnabled ? areShipmentDetailsLoadingPaginated : areShipmentDetailsLoading;
  return (
    <>
      <Helmet title="Shipment" />
      <Container fluid className="p-0">
        {isLoading ? <Loader /> : null}
        {shipmentData && !isLoading ? <ShipmentDetails shipmentDetails={shipmentData} /> : null}
      </Container>
    </>
  );
}
