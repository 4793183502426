/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Stack, Badge, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DropdownOptionsModel } from '../../../../components/shared/SelectInput.models';
import { DriverAvailability } from '../../../../redux/models/driver.availability.models';
import { DateService } from '../../../../utils/dateService';
import { TripBoardModel } from '../../../../redux/models/trip.models';
import { Driver } from '../../../../redux/models/settings.models';
import { TripPlan } from '../../../../redux/slices/planner/planner.models';
import CrapTable from '../components/table/CrapTable';

interface Props {
  plan: TripPlan;
  availability: DriverAvailability[];
  driver: Driver;
  drivers: DropdownOptionsModel[];
  isOver?: boolean;
  shouldExpandAllItems: boolean;
  trips: TripBoardModel[];
  yard: string;
}

function DriverSection({
  availability, driver, drivers, shouldExpandAllItems, trips, yard, plan, isOver
}: Props) {
  // const dispatch = useAppDispatch();
  const driverName = driver.data.samsara_name || 'No Name';
  const outSideCartage = driver.data.outside_cartage || false;
  const isTrips = trips.length > 0;
  const numTrips = trips.length === 1 ? ` ${trips.length} Trip` : ` ${trips.length} Trips`;
  const [isExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const activeStyles: any = {};
  if (isOver) {
    activeStyles.background = '#F1F5F9';
  }
  useEffect(() => setIsItemExpanded(shouldExpandAllItems), [shouldExpandAllItems]);
  return (
    <Stack style={activeStyles} className="rounded">
      <Button type="button" variant="link" onClick={() => setIsItemExpanded(!isExpanded)}>
        <Stack direction="horizontal" gap={2}>
          <h5>{driverName}</h5>
          {outSideCartage && <Badge bg="secondary" className="mb-2">Outside Cartage</Badge>}
          {!isExpanded && isTrips && <p className="mt-2 px-2">{numTrips}</p>}
          <FontAwesomeIcon
            width={25}
            icon={isExpanded ? faChevronDown : faChevronRight}
            className="custom-collapse-icon mb-2"
          />
        </Stack>
      </Button>
      <div className="px-2">
        {isExpanded && availability.map((av) => {
          const startTime = DateService.getHHmmFormat(av.data.start_time);
          const finishTime = DateService.getHHmmFormat(av.data.finish_time);
          const spansMidnight = DateService.spansMidnight(startTime, finishTime);
          return (
            <Stack key={av.entity_id} direction="horizontal" gap={2}>
              <p>{`${DateService.getDay(av.data.weekday)}: ${startTime} - ${finishTime}`}</p>
              {spansMidnight && <Badge bg="secondary" className="mb-2">Night Shift</Badge>}
            </Stack>
          )
        })}
        {isExpanded && yard && yard !== '' && <p>{yard}</p>}
        {isExpanded && (
          <CrapTable
            driverId={driver.entity_id}
            plan={plan}
            trips={trips}
            drivers={drivers}
          />
        )}
      </div>
    </Stack>
  );
}

DriverSection.defaultProps = {
  isOver: false,
};

export default DriverSection;