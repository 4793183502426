import { Api } from "../../../services/services"
import { getPostSearchBody } from "../settings.utils";

export const loadOrgs = (orgCode: string) => {
  const filters = getPostSearchBody('name', orgCode);
  return Api.Organisation.find(filters);
};

export const loadIntegrations = (orgId: string) => {
  return Api.Integrations.orgIntegrations(orgId);
};

export const getOAuth2Url = (appId: string) => {
  return Api.Integrations.authorizeOAuth2(appId);
};
