/* eslint-disable import/no-cycle */
import { Descendant } from 'slate';
// import { Timeliness } from "../../utils/timeliness";
import { DataModel } from "./core.models";
import { Document } from "./file.models";
import { Shipment, ShipmentListViewData, TripBoardShipment } from "./shipment.models";

export interface MoveData {
  action: string;
  actual_arrival_time: string | null;
  actual_departure_time: string | null;
  board_id: string;
  destination_id: string;
  documents?: Document[];
  entity_id?: string;
  notes?: string;
  dispatch_notes?: Descendant[];
  origin_id: string;
  position: number;
  samsara_notes?: string;
  scheduled_arrival_time: string | null;
  scheduled_departure_time: string | null;
  shipment_ids: string[];
  show_appt?: boolean;
  schedule_tbd?: boolean;
  temp: string;
  trailer_ids: string[];
  trip_id: string;
}

export type Move = DataModel<MoveData>;

export enum TripStatus {
  ReadyToDispatch = 'ready-to-dispatch',
  Dispatched = 'dispatched',
  DriverConfirmed = 'driver-confirmed',
  Complete = 'complete',
}

export interface TripData {
  board_id: string;
  driver_id: string;
  date: string;
  group_id: string;
  moves: Move[];
  scheduled_start?: string;
  scheduled_end?: string;
  status: string;
  trip_no: string;
}

export type Trip = DataModel<TripData>;

export interface TripDetailsData {
  board_id: string;
  date: string;
  driver_id: string;
  group_id: string;
  moves: Move[];
  role_ids?: string[];
  samsara_route_id: string | null;
  shipments: Shipment[];
  status: string;
  trip_no: string;
  vehicle_id: string;
  fuel_rate?: number | null;
  estimated_distance_metres?: number | null;
  estimated_duration_seconds?: number | null;
  scheduled_start?: string;
  scheduled_end?: string;
}

export type TripDetails = DataModel<TripDetailsData>;

export interface TripListViewData {
  actual_route_start_time?: string;
  actual_route_end_time?: string;
  board_id: string;
  end_date: string;
  entity_id?: string;
  driver_id: string;
  group_id: string;
  moves: MoveData[];
  route: string[];
  scheduled_route_start_time?: string;
  scheduled_route_end_time?: string;
  shipments: ShipmentListViewData[];
  start_date: string;
  status: string;
  trip_id: string;
  trip_no: string;
  vehicle_id: string;
}

export interface TripBoardModel {
  actual_route_end_time?: string;
  actual_route_start_time?: string;
  apt: string;
  bol: string;
  billingType: string;
  containerNo: string;
  customer: string;
  dangerous_goods: boolean;
  driver: string;
  driverId: string;
  dry: boolean;
  eta: string;
  group: string;
  groupId: string;
  id: string;
  modified_at?: string;
  moves: MoveData[];
  notes: string;
  purchase_order_no: string;
  route: string;
  scheduled_route_start_time?: string;
  scheduled_route_end_time?: string;
  shipments: TripBoardShipment[];
  startDate: string;
  status: string;
  temp: string[];
  trailer: string;
  tripNo?: string;
  trip_template_ids?: string[];
  version?: string;
}

export type TripListView = DataModel<TripListViewData>;

export enum DispatchNotificationType {
  Email = 'email',
  Sms = 'sms'
}

export interface DispatchNotificationParams {
  type: DispatchNotificationType;
  send_to: string;
  company_name: string;
  reply_to: string;
  driver_name: string;
  user_timezone: string;
}
