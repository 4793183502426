/* eslint-disable max-len */
import {
  createContext, useEffect, useMemo, useReducer,
} from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from "jwt-decode";

import { auth0Config, LOCAL_STORAGE_TOKEN_KEY, setOrgCode } from '../config';
import Analytics from '../utils/analytics';
import { DateService } from '../utils/dateService';

const INITIALIZE = 'INITIALIZE';
const START_AUTH_REDIRECT = 'START_AUTH_REDIRECT';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';

const aquadashLogo = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/640b6ff7561700f7f0bddbfb_AquaDash-transparent.png';
const logo = process.env.REACT_APP_LOGO_URL || aquadashLogo;
const title = process.env.REACT_APP_LOGIN_TITLE || 'Welcome to AquaDash';
const description = process.env.REACT_APP_LOGIN_DESCRIPTION || 'Sign in to continue';

export const isTokenValid = (token) => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  const exp = decoded?.exp || null;
  if (exp) {
    const isFuture = DateService.isFutureUnix(exp);
    return isFuture
  }
  return false;
}

export const auth0Client = new Auth0Client({
  clientId: auth0Config.clientId || '',
  domain: auth0Config.domain || '',
  authorizationParams:{
    audience: auth0Config.audience || '',
    redirect_uri: window.location.origin,
  },
  cacheLocation: 'localstorage',
  logo_url: logo,
  page_title: title,
  page_description: description,
});

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === START_AUTH_REDIRECT) {
    const { isLoggingIn } = action.payload;
    return {
      ...state,
      isLoggingIn,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, isLoggingIn: false, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        await auth0Client.checkSession();
        // const isAuthenticated = await auth0Client.isAuthenticated();
        const token = await auth0Client.getTokenSilently();
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        const isAuthed = isTokenValid(token);
        if (token && isAuthed) {
          const user = await auth0Client.getUser();
          const userId = user?.email;
          const orgs = user?.orgs || [];
          if (orgs.length === 1) {
            const org = orgs[0];
            setOrgCode(org);
          };
          if (userId) Analytics.identify(userId);
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: user || null },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: null },
          });
        }
      } catch (err) {
        Analytics.capture(err);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };
    initialize();
  }, []);

  const signIn = async () => {
    dispatch({
      type: START_AUTH_REDIRECT,
      payload: { isLoggingIn: true },
    });
    const loginRedirect = await auth0Client?.loginWithRedirect({
      appState: {
        goToProjects: true, // state to restore when getting redirected back
      }
    });
    return loginRedirect;
  };

  /// https://community.auth0.com/t/angular-10-spa-error-on-redirect-after-login-there-are-no-query-params-available-for-parsing/57805/5
  const onloadLogin = async () => {
    try {
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: true },
      });
      const redirectResult = await auth0Client.handleRedirectCallback();
      const isAuthenticated = await auth0Client?.isAuthenticated();
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: false },
      });
      if (isAuthenticated) {
        const user = await auth0Client?.getUser();
        const token = await auth0Client.getTokenSilently();
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        const userId = user?.email;
        if (userId) Analytics.identify(userId);
        dispatch({ type: SIGN_IN, payload: { user: user || null } });
        window.removeEventListener('load', onloadLogin);
        // window.location.replace(`${window.location.origin}/projects`);
        return redirectResult;
      }
      return window.removeEventListener('load', onloadLogin);
    } catch (error) {
      Analytics.capture(error);
      return {};
    }
  };

  if (window.location.href.includes('code')) window.addEventListener('load', onloadLogin);

  const signOut = () => {
    auth0Client?.logout({ openUrl: false });
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => console.log(email);

  const providerValue = useMemo(
    () => ({
      ...state,
      method: 'auth0',
      user: {
        id: state?.user?.sub,
        avatar: state?.user?.picture,
        email: state?.user?.email,
        displayName: state?.user?.nickname,
        role: 'user',
        orgs: state?.user?.orgs ?? [],
      },
      signIn,
      signOut,
      resetPassword,
    }),
    [state],
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };