import React from 'react';
import { Table as BTable } from 'react-bootstrap';
import { TripBoardModel } from '../../../../../redux/models/trip.models';
import { DropdownOptionsModel } from '../../../../../components/shared/SelectInput.models';
import { TripPlan } from '../../../../../redux/slices/planner/planner.models';
// import TableRow from './TableRow';
import CrapTableRow from './CrapTableRow';
import { sortTripByTBDDate } from '../../../constants/boards.constants';

interface TableProps {
  plan: TripPlan;
  trips: TripBoardModel[];
  drivers: DropdownOptionsModel[];
  driverId: string;
}

function CrapTable({ driverId, plan, trips, drivers }: TableProps) {
  // trailer, apt, billingType, route, driver, status
  const log = false;
  if (log) console.log(drivers);
  const tripData = trips || [];
  const data = tripData.sort((a, b) => sortTripByTBDDate(a, b));
  const isTrips = data && data.length > 0;
  return (
    <BTable striped bordered hover responsive size="sm">
      <thead>
        <tr>
            <th><span>{' '}</span></th>
            <th>Trailer</th>
            <th>Apt</th>
            <th>Quote Template</th>
            <th>Route</th>
            {/* <th>Driver</th> */}
            <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {isTrips && data.map((trip, index) => {
          if (!trip) {
            return (
              <tr key={`trip-${index}`} className="flex-fill">
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
                <td>{' '}</td>
              </tr>
            )
          }
          return (
            <CrapTableRow
              key={trip.id}
              index={index}
              driverId={driverId}
              trip={trip}
              plan={plan}
            />
          )
        })}
      </tbody>
    </BTable>
  );
}

export default CrapTable;
