import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddressesObject, selectFleetObject } from '../../../redux/slices/settings';
import { selectUpdatedTrips, setUpdatedTripsArray } from '../../../redux/slices/trailerChanges';
import { TRAILER_CHANGE_TABLE_HEADERS } from './trailerChange.constants';
import { formatTCMoves } from './trailerChange.utils';
import TrailerChangeTableRow from './TrailerChangeTableRow';

const headers = TRAILER_CHANGE_TABLE_HEADERS;

export default function TrailerChangeModalTable({
  name,
  moves,
  shipments,
  fleetLocations,
  tripNo,
}) {
  const dispatch = useDispatch();
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  // filteredTrips has updated moves data. moves prop from API call
  const filteredTrips = useSelector(selectUpdatedTrips);

  const formattedMoves = formatTCMoves(moves, filteredTrips, tripNo, addresses);

  const onTrailerChangeInput = (position) => (prop, value) => {
    const updatedTripsArr = filteredTrips.map((trip) => {
      if (trip.data.trip_no === tripNo) {
        return {
          ...trip,
          data: {
            ...trip.data,
            moves:
            trip.data.moves.map((move) => {
              if (move.data.position === position) {
                return {
                  ...move,
                  data: { ...move.data, [prop]: value },
                };
              }
              return move;
            }),
          },
        };
      }
      return trip;
    });
    dispatch(setUpdatedTripsArray(updatedTripsArr));
  };

  return (
    <Table className={`${name}-move__table`} data-cy={`${name}-move__table`}>
      <thead className={`${name}-head`}>
        <tr>
          {headers.map((header) => (
            <th key={header.key}>{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {moves && formattedMoves.map((move, index) => (
          <tr
            key={`${move.id}_${index}`}
            id={`${name}_tableRow-${index}`}
          >
            {headers.map((header) => (
              <td key={`${header.key}_${move.id}`} data-cy={`trailerChanges_${header.key}_${index}`}>
                <TrailerChangeTableRow
                  move={move}
                  shipments={shipments}
                  prop={header.key}
                  name={name}
                  fleet={fleet}
                  fleetLocations={fleetLocations}
                  onTrailerChangeInput={onTrailerChangeInput}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
