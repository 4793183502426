import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import TripsTable from '../TripTable/TripsTable';
import { selectTripsByCardId, sendTripRouteAsync } from '../../../redux/slices/trips/trips';
import DriverCardMenu from './DriverCardMenu';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { DateService } from '../../../utils/dateService';
import useAppSettings from '../../../hooks/useAppSettings';
import { formatOrgName } from '../../../utils/core.utils';
import { getTripIdsForDispatch } from '../TripPlanner/table.utils';

const NO_TRIPS_MESSAGE = 'No trips for this driver';
const getDispatchMessage = (numTrips) => {
  const header = `Would you like to dispatch ${numTrips} trips to this drivers`;
  const statuses = `Completed, dispatched and driver confirmed`
  return `${header} ${statuses} trips won't be dispatched.`;
};

export default function DriverCard({ driver, shouldExpandAllItems, headers }) {
  const { async_dispatch: asyncDispatch } = useFeatureFlags();
  const { timezone } = useAppSettings();
  const { orgCode } = useParams();
  const isAsyncDispatchEnabled = asyncDispatch?.is_enabled || false;
  const driverName = driver.data?.samsara_name || 'No Name';
  const driverId = driver.entity_id;
  const driverTrips = useSelector((state) => selectTripsByCardId(state, driverId));
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const [confirmDispatchOpen, setConfirmDispatchOpen] = useState(false);
  const [isAssigningDrivers, setIsAssigningDrivers] = useState(false);
  const [isItemExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const orgName = formatOrgName(orgCode);
  const isUnassigned = driver.entity_id === 'UNASSIGNED-DRIVERS';
  const handleDispatch = async () => {
    try {
      setIsAssigningDrivers(true);
      const tripIds = getTripIdsForDispatch(driverTrips);
      const offset = DateService.getTimezoneOffset(timezone);
      const params = {
        trip_ids: tripIds,
        user_timezone: offset,
        company_name: orgName,
      };
      const response = await sendTripRouteAsync(params);
      if (response.status === 200) {
        setConfirmDispatchOpen(false);
        toast(`${driverName} trips queued for dispatching.`, { type: 'success' });
      }
    } catch (error) {
      setIsAssigningDrivers(false);
    }
  };
  useEffect(() => setIsItemExpanded(shouldExpandAllItems), [shouldExpandAllItems]);

  return (
    <Accordion activeKey={isItemExpanded ? '0' : ''}>
      <Card>
        <Accordion.Item eventKey="0">
          <Card.Header
            className="group-card-header"
            onMouseEnter={() => setIsMenuShowing(true)}
            onMouseLeave={() => setIsMenuShowing(false)}
          >
            <Card.Title tag="h5">{driverName}</Card.Title>
            {!isUnassigned && isAsyncDispatchEnabled && isMenuShowing && (
              <div className="group-card-menu">
                <DriverCardMenu setConfirmDispatchOpen={setConfirmDispatchOpen} />
              </div>
            )}
            <div
              className="custom-collapse-icon-wrapper"
              onClick={() => setIsItemExpanded(!isItemExpanded)}
            >
              <FontAwesomeIcon
                width={25}
                he={25}
                icon={faChevronDown}
                className="custom-collapse-icon"
              />
            </div>
          </Card.Header>
          <Accordion.Body style={{ background: 'white', padding: 0 }}>
            <Card.Body>
              <TripsTable
                trips={driverTrips}
                group={driverName}
                headers={headers}
                noTripsMessage={NO_TRIPS_MESSAGE}
              />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
      {confirmDispatchOpen && (
        <ConfirmModal
          title={`Confirm Dispatch ${getTripIdsForDispatch(driverTrips).length} Trips`}
          details={getDispatchMessage(getTripIdsForDispatch(driverTrips).length)}
          btnTitle="Dispatch"
          saveDisabled={getTripIdsForDispatch(driverTrips).length === 0 || isAssigningDrivers}
          shouldShowModal={confirmDispatchOpen}
          isSaving={isAssigningDrivers}
          handleSave={handleDispatch}
          cancelSave={() => setConfirmDispatchOpen(false)}
        />
      )}
    </Accordion>
  );
}
