import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Spinner, Stack } from 'react-bootstrap';
import { getSettingByName, getTripTemplate, updateTripTemplate } from '../../redux/slices/settings';
import { TripTemplate } from '../../redux/models/settings.models';

import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { ControlInput } from '../../components/shared/Input';

const PARAM_PROPS = {
  name: 'name',
};

const PAGE = 'edit_trip_template_input';

export default function TripTemplateEditor() {
  const dispatch = useAppDispatch();
  const { entityId } = useParams();
  const navigate = useNavigate();

  const [template, setTemplate] = useState<TripTemplate | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (prop: string, value: string) => {
    if (!template) return;
    setTemplate({
      ...template,
      data: {
        ...template.data,
        [prop]: value,
      },
    });
  };
  const handleClose = () => navigate(-1);
  const handleSave = async () => {
    if (!template) return;
    try {
      setIsSaving(true);
      const response = await updateTripTemplate(template);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${template?.data.name || 'dispatch template'}`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const handleLoad = async (id: string) => {
      const response = await getTripTemplate(id);
      if (response.status === 200) setTemplate(response.data.data);
      setIsLoading(false);
    }
    if (entityId) handleLoad(entityId);
  }, [entityId]);
  
  if (isLoading) return <Spinner animation="border" variant="light" />;
  return (
    <Card>
      <Card.Header as="h4">Trip Template</Card.Header>
      <Card.Body>
        <ControlInput
          dataCy={`${PAGE}_${PARAM_PROPS.name}`}
          name={PARAM_PROPS.name}
          type="text"
          page={PAGE}
          value={template?.data?.name || ''}
          labelText="Name"
          handleChange={handleChange}
        />
      </Card.Body>
      <Card.Footer>
        <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>Save
            {isSaving && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                style={{ marginLeft: '4px' }}
              />
            )}
          </Button>
        </Stack>
      </Card.Footer>
    </Card>
  );
}
