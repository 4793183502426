import { DateService } from '../../../../../../utils/dateService';

const sortMoves = (moves) => [...moves].sort((a, b) => (a?.position || 0) - (b?.position || 0));
const firstMove = (moves, prop) => sortMoves(moves).find((move) => move[prop]);

const formatAppointment = (moves) => {
  const first = firstMove(moves, 'date');
  return DateService.getUnixTime(first?.date);
};

function formatRoute(moves, addresses) {
  if (!moves.length) {
    return 'No Origin >> No Destination';
  }
  const [first] = [...moves].sort((a, b) => a.position - b.position);
  const last = moves[moves.length - 1];
  const origin = addresses[first.destination_id]?.data?.samsara_name || 'No Origin';
  const destination = addresses[last.destination_id]?.data?.samsara_name || 'No Destination';
  if (moves.length === 1) {
    return `${origin}`;
  }
  return `${origin} >> ${destination}`;
}

export const formatTrips = (trips, addresses, drivers, boards, groups) => trips.map((trip) => ({
  id: trip.entity_id,
  board: boards[trip?.board_id]?.data?.name,
  group: groups[trip?.group_id]?.data?.name,
  driver: drivers[trip.driver_id]?.data?.samsara_name,
  route: formatRoute(trip.moves || [], addresses || {}),
  date: DateService.getYYYYMMDDFormat(trip.date),
  appointment: formatAppointment(trip.moves || []),
  trip_no: trip.trip_no,
})).sort((a, b) => a.appointment - b.appointment);
